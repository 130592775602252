<template>
  <div class="avatarContentEditionPanel">
    <div class="avatarContentEditionContainer">
      <div class="avatarContentEditorTitle">{{ $t('aiAvatar.script') }}</div>

      <!-- INPUT TEXT -->
      <w-input type="textarea" v-model="textToGenerate" @input="onTextInput" class="contentTextAreaAI"
               :class="{overtext:textToGenerate.length>maxlengthOfCharacter}"
               :placeholder="$t('aiAvatar.content.placeholder')"
               :maxlength="maxlengthOfCharacter"></w-input>
      <!-- upgrade freemium message -->
      <div v-if="isFreemium" class="upgradeMessage">{{ $t('aiAvatar.content.upgrade') }}
      <span> <upgrade-plan-button size="small" color="text" :with-label="false"/></span></div>


      <!-- SELECT LANGUAGE -->
      <div class="lineSelect">
        <div class="labelSelect">{{ $t('aiAvatar.langsSelector') }}</div>
        <w-select class="langSelector" size="small" @input="onAvatarLangChange" :value="langSelected">
          <option :value="listOfVoicesLangs[0].value" :key="'voice_lang_'+listOfVoicesLangs[0].value">
            {{ listOfVoicesLangs[0].label }}
          </option>
          <hr>
          <option v-for="lang in listOfVoicesLangs.slice(1)" :value="lang.value" :key="'voice_lang_'+lang.value">
            {{ lang.label }}
          </option>
        </w-select>
      </div>

      <!-- SELECT VOICES -->
      <div class="lineSelect">
        <div class="labelSelect labelVoiceSelector">{{ $t('aiAvatar.voicesSelector') }}</div>
        <w-select class="voiceSelector" size="small" :value="voiceSelected" @input="onAvatarVoiceChange">
          <option v-for="voice in listOfVoice" :value="voice.value" :key="'voice_'+voice.value">
            {{ voice.label }}
          </option>
        </w-select>

        <w-button :icon="playingVoice?'stop':'volume-high'" :enabled="textToGenerate"
                  @click="previewVoice" :tooltip="$t('aiAvatar.tooltip.previewVoice')"
                  class="buttonPlayPreview" size="small" color="outlined"
                  :loading="loadingVoice"></w-button>
      </div>


      <div class="lineButton" v-if="slides.length > 0">
        <b-dropdown aria-role="list"  id="buttonDropDown">
          <template #trigger="{ active }">
            <w-button class="buttonOneSlide" :expanded="true" :icon-right="active ? 'menu-up' : 'menu-down'"  :enabled="!!textToGenerate"
                      :loading="isAvatarGenerating || loadingVoice"
                      color="primary" size="small">
              {{$t("aiAvatar.generate")}}
            </w-button>
          </template>
          <b-dropdown-item aria-role="listitem"  @click="generateSlide" class="lineButtonGenerate"><w-icon class="iconButtonsGenerate" icon="play-box-outline"/><span class="labelButtonGenerate">{{$t('aiAvatar.generateThisSlide')}}</span></b-dropdown-item>
          <b-dropdown-item aria-role="listitem" :class="{disabledFreemium:isFreemium}" @click="isFreemium?goToPayment():generateAllSlide()" class="lineButtonGenerate" >
            <w-icon class="iconButtonsGenerate" icon="animation-play-outline"/>
            <span class="labelButtonGenerate">{{$t('aiAvatar.generateAllSlide')}}</span>
            <upgrade-plan-button v-if="isFreemium" size="small" color="secondary" :with-label="true"/>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="lineButton" v-else>
        <w-button class="buttonOneSlide" :expanded="true" @click="generateSlide" icon="play-box-outline" :enabled="!!textToGenerate"
                  :loading="isAvatarGenerating || loadingVoice"
                  color="primary" size="small">
          {{$t("aiAvatar.generate")}}
        </w-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Emit, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Howl} from 'howler';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WSwitch from '@/components/wrapper/w-switch.vue';
import WHelp from '@/components/wrapper/w-help.vue';
import CustomVBUploader from '@/components/recorder/sticker/buttons/CustomVBUploader.vue';
import {
  LIST_OF_AVATAR,
  Text2SpeachParams
} from "@/store/AIAvatar/aiavatarGenerationModel";
import {
  AVATAR_EDITING_TEXT,
  SELECT_AI_AVATAR,
  SELECT_AI_BACKGROUND,
  SELECT_AI_LANG, SELECT_AI_VOICE
} from "@/store/AIAvatar/AIAVatarAction";
import WSelect from "@/components/wrapper/w-select.vue";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";
import WInput from "@/components/wrapper/w-input.vue";
import {listOfVoice, listOfVoicesLanguages} from "@/utils/voiceUtil";
import {isSpeach} from "@/utils/speachUtils";
import {isFreemium, MAX_LENGTH_AIAVATAR_FREEMIUM, MAX_LENGTH_AIAVATAR_PREMIUM} from "@/utils/workspaceUtil";
import {TEXT_2_SPEECH_GENERATE} from "@/store/media/mediaAction";
import {base64ToBlob} from "@/utils/audioUtil";
import {Slide} from "@/store/slides2Video/slides2VideoModels";
import {clone} from "@/utils/cloneUtil";
import {updateCurrentSlide} from "@/utils/slides/slideUtil";


@Component({
  components: {
    WInput,
    UpgradePlanButton,
    WSelect,
    CustomVBUploader, WHelp, WSwitch, CenterCenterPanel, WModal, WToolTip, WIcon, WButton, VideoSource
  },
  computed: {},
})
export default class AIAvatarContentEditionPanel extends Vue {

  private playingVoice = false;
  private audioPlayer: Howl | null = null;
  private loadingVoice = false;
  private textToGenerate: string = "";


  public mounted(){
    this.onCurrentSlideChange();
    const isVoiceAvaialbleForGender=!!this.listOfVoice.find((v)=>v.value===this.voiceSelected)
    if(!isVoiceAvaialbleForGender) {
      store.dispatch(SELECT_AI_VOICE, this.listOfVoice[0].value)
    }
  }

  get isAvatarGenerating() {
    return store.getters.isAvatarGenerating
  }

  get selectedAvatar() {
    return store.getters.getAvatarSelected;
  }

  get slides(): Slide[] {
    return store.getters.slides;
  }

  get currentSlide(): Slide | null {
    if (store.getters.getCurrentSlideIndex > -1) {
      return store.getters.slides[store.getters.getCurrentSlideIndex];
    }
    return null;
  }

  get langSelected() {
    return store.getters.getAvatarLangSelected || this.listOfVoicesLangs[0].value
  }

  get gender() {
    return LIST_OF_AVATAR.find((av) => {
      return av.avatar === this.selectedAvatar
    })?.gender
  }

  get listOfVoicesLangs() {
    const autoDetectOption = {label: this.$t('generic.autodetect'), value: null};
    return [autoDetectOption, ...listOfVoicesLanguages];
  }

  get listOfVoice() {
    return listOfVoice.filter((voice) => {
      return voice.gender === this.gender
    })
  }

  get voiceSelected() {
    return store.getters.getAvatarVoiceSelected
  }

  get isFreemium(): boolean {
    return isFreemium()
  }

  get maxlengthOfCharacter() {
    if (this.isFreemium) {
      return MAX_LENGTH_AIAVATAR_FREEMIUM;
    } else {
      return MAX_LENGTH_AIAVATAR_PREMIUM;
    }
  }

  private onAvatarLangChange(lang) {
    store.dispatch(SELECT_AI_LANG, lang)
    if(this.currentSlide){
      const newSlide=clone(this.currentSlide) as Slide;
      newSlide.lang=lang;
      updateCurrentSlide(newSlide);
    }
  }

  private onAvatarVoiceChange(voice) {
    store.dispatch(SELECT_AI_VOICE, voice)
    if(this.currentSlide){
      const newSlide=clone(this.currentSlide) as Slide;
      newSlide.voice=voice;
      updateCurrentSlide(newSlide);
    }
  }

  private onTextInput() {
    store.dispatch(AVATAR_EDITING_TEXT, this.textToGenerate);
    // update slides
    if(this.currentSlide){
      const newSlide=clone(this.currentSlide);
      newSlide.script=this.textToGenerate;
      updateCurrentSlide(newSlide);
    }
  }

  private goToPayment(){
    this.$router.push({name: 'MyWorkspacePayment'});
  }

  @Emit('generateSlide')
  private generateSlide() {
    return;
  }

  @Emit('generateAllSlide')
  private generateAllSlide() {
    return
  }

  @Watch("currentSlide")
  private onCurrentSlideChange() {
    if (this.currentSlide) {
      this.textToGenerate = this.currentSlide.script
      store.dispatch(AVATAR_EDITING_TEXT, this.currentSlide.script);
      if(this.currentSlide.lang){
        store.dispatch(SELECT_AI_LANG,this.currentSlide.lang)
      }
      if(this.currentSlide.voice){
        const isVoiceAvaialbleForGender=!!this.listOfVoice.find((v)=>v.value===this.voiceSelected)
        if(isVoiceAvaialbleForGender) {
          store.dispatch(SELECT_AI_VOICE, this.currentSlide.voice)
        }else{
          store.dispatch(SELECT_AI_VOICE,this.listOfVoice[0].value)
        }
      }
    } else {
      this.textToGenerate = "";
    }
    store.dispatch(AVATAR_EDITING_TEXT, this.textToGenerate);
  }

  @Watch("selectedAvatar")
  private onAvatarChange(){
    const isVoiceAvaialbleForGender=!!this.listOfVoice.find((v)=>v.value===this.voiceSelected)
    if(!isVoiceAvaialbleForGender){
      store.dispatch(SELECT_AI_VOICE,this.listOfVoice[0].value)
    }
  }

  /***
   ** PUBLIC METHODS
   **/

  // Listening the voice
  public async previewVoice() {
    if (this.playingVoice) {
      this.stopPreviewVoice();
      return;
    } else {
      this.stopPreviewVoice();
    }

    // STOP
    if (this.audioPlayer) {
      this.audioPlayer.stop();
      this.audioPlayer = null;
      this.playingVoice = false;
      return;
    }
    this.loadingVoice = true;
    const text2SpeachParams = new Text2SpeachParams()
    text2SpeachParams.text = this.textToGenerate;
    text2SpeachParams.voice = this.voiceSelected;
    text2SpeachParams.lang = this.langSelected;
    const base64 = await store.dispatch(TEXT_2_SPEECH_GENERATE, text2SpeachParams);
    const url = URL.createObjectURL(base64ToBlob(base64, 'audio/mp3'));
    this.loadingVoice = false;
    this.audioPlayer = new Howl({
      src: [url],
      autoplay: true,
      html5: true,
      onend: () => {
        this.playingVoice = false;
      },
      volume: 1,
    });
    this.audioPlayer.play();
    this.playingVoice = true;
  }


  // Stoping the preview voice
  public async stopPreviewVoice() {

    if (this.audioPlayer) {
      this.audioPlayer.stop();
      this.audioPlayer = null;
      this.playingVoice = false;
    }
  }

  public async getAudioInformationForText(): Promise<{ seconds: number, blob: Blob }> {
    return new Promise(async (resolve, reject) => {
      this.stopPreviewVoice();
      const text2SpeachParams = new Text2SpeachParams()
      text2SpeachParams.text = this.textToGenerate;
      text2SpeachParams.voice = this.voiceSelected;
      text2SpeachParams.lang=this.langSelected
      const base64 = await store.dispatch(TEXT_2_SPEECH_GENERATE, text2SpeachParams);
      const blob = base64ToBlob(base64, 'audio/mp3');
      const url = URL.createObjectURL(blob);
      this.audioPlayer = new Howl({
        src: [url],
        autoplay: true,
        html5: true,
        onload: () => {
          this.audioPlayer.stop();
          resolve({seconds: this.audioPlayer.duration(), blob: blob});
        },
        onend: () => {
          this.playingVoice = false;
        },
        volume: 0,
      });
      this.audioPlayer.play();
    })
  }
}
</script>
<style lang="scss">
#buttonDropDown {
  width: 100%;
  .dropdown-trigger {
    width: 100%;
  }
}
// no scope to manage textarea size
.contentTextAreaAI {
  .textarea {
    min-height: 288px;
    height: 288px;
    //resize: none;
  }
  &.overtext{
    .help{
      color: red;
    }
  }
}
</style>
<style lang="scss" scoped>
.avatarContentEditionPanel {
  width: 100%;
  height: 100%;


  .avatarContentEditionContainer {
    align-items: center;
    text-align: left;
    font-weight: 700;
    color: var(--black);

    .avatarContentEditorTitle {
      color: var(--black);
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 8px;
    }

    .lineButton{
      margin-top: 8px;
      .buttonOneSlide{
        margin-right: 8px;
        margin-bottom: 16px;
      }
      .lineButtonGenerate {
        display: flex;
        align-items: center;
        .iconButtonsGenerate {
          margin-right: 8px;
        }
        .labelButtonGenerate{
          margin-right: 8px;
        }
        &.disabledFreemium{
          .labelButtonGenerate{
            cursor: not-allowed;
            opacity: 0.3;
          }
        }
      }
    }
    .lineSelect {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 16px;
      .labelSelect {
        position: relative;
        text-align: left;
        font-size: 14px;
        color: var(--black);
        font-weight: 700;
        justify-content: space-between;
        align-items: flex-end;
        display: flex;
        margin-right: 16px;
        min-width: 90px;
      }
      .langSelector, .voiceSelector {
        flex:1;
      }
      .buttonPlayPreview {
        align-content: center;
        margin-left: 8px;
        margin-bottom: 12px;
        cursor: pointer;
      }

    }



    .upgradeMessage {
      font-size: 14px;
      display: flex;
      padding-right: 0px;
      color: var(--light2);
    }


    .contentTextAreaAI {
      margin-bottom: -16px;
    }

    .buttonValidateStep {
      position: absolute;
      bottom: 16px;
      right: 16px;
    }
  }
}
</style>
