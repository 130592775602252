// SETTINGS ACTION
export const RESET_PERSIST_STORE = 'RESET_PERSIST_STORE';
export const SUBTITLE_LANG = 'SUBTITLE_LANG';
export const SUBTITLE_VOICE_OVER_LANG='SUBTITLE_VOICE_OVER_LANG';

// NEWS PANEL
export const SET_LAST_NEWS_DATE = 'SET_LAST_NEWS_DATE';
export const SET_LAST_VERSION_NEWS = 'SET_LAST_VERSION_NEWS';

export const FIRST_WEET_CONGRAT = 'FIRST_WEET_CONGRAT';
export const ONBOARDING_RECORDER_1 = 'ONBOARDING_RECORDER_1';
export const ONBOARDING_PLAYER_1 = 'ONBOARDING_PLAYER_1';
export const ONBOARDING_SECTION_1 = 'ONBOARDING_SECTION_1';

// TIME_LINE_VISU
export const TIME_LINE_VISU = 'TIME_LINE_VISU';

// Select a workspace
export const SELECT_WORKSPACE = 'SELECT_WORKSPACE';

// CHANGE PLAYER SPEED
export const CHANGE_PLAYER_SPEED = 'CHANGE_PLAYER_SPEED';
export const CHANGE_PLAYER_VOLUME = 'CHANGE_PLAYER_VOLUME';

// EXP Features :
export const EXPERIMENTAL_FEATURE = 'EXPERIMENTAL_FEATURE';

export const STORE_LINK_REDIRECT_AFTER_PAYMENT="STORE_LINK_REDIRECT_AFTER_PAYMENT"


// SPEACH
export const SETUP_FROM_SPEACH_TOKEN="SETUP_FROM_SPEACH_TOKEN";

// AVATAR
export const SAVE_AVATAR="SAVE_AVATAR"
export const SAVE_AVATAR_VOICE="SAVE_AVATAR_VOICE"
export const SAVE_AVATAR_LANG="SAVE_AVATAR_LANG"
export const SAVE_AVATAR_BACKGROUND="SAVE_AVATAR_BACKGROUND"
