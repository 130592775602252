import axios from "axios";
import {BACK_ENDPOINT} from "@/store/conf/api";
import delay from "delay";
import {Slide} from "@/store/slides2Video/slides2VideoModels";
import {
  DOCUMENT_TO_LLM,
  RESET_SLIDES_2_VIDEO, SELECT_SLIDE, SLIDE_TO_LLM,
  SLIDES_TO_LLM_IN_PROGRESS,
  SLIDES_TO_LLM_RESPONSE, UPDATE_SLIDES
} from "@/store/slides2Video/slides2VideoAction";
import {Vue} from "vue-property-decorator";
import {clone} from "@/utils/cloneUtil";
import store from "@/store";
import {OPEN_AVATAR_EDITING_CONTENT} from "@/store/AIAvatar/AIAVatarAction";
export const SLIDE_API = BACK_ENDPOINT + '/slides';


export default {
  state: {
    proceedSlideInProgress:false,
    slides:[],
    currentSlideIndex:-1
  },
  mutations: {
    [SLIDES_TO_LLM_IN_PROGRESS]: (state: any, value: boolean) => {
      state.proceedSlideInProgress=value;
    },
    [UPDATE_SLIDES]: (state: any, value: Slide[]) => {
      Vue.set(state,'slides',value);
    },
    [SELECT_SLIDE]:(state:any,index:number)=>{
      state.currentSlideIndex=index;
    }
  },
  getters: {
    isProceedSlideInProgress: (state: any): boolean => {
      return state.proceedSlideInProgress;
    },
    slides:(state: any):Slide[] => {
      return state.slides
    },
    getCurrentSlideIndex(state: any):number {
      return state.currentSlideIndex;
    }
  },
  actions: {
    [RESET_SLIDES_2_VIDEO]({commit, dispatch}: { commit: any, dispatch: any }) {
      commit(UPDATE_SLIDES,[])
      commit(SELECT_SLIDE,-1)
    },
    [SELECT_SLIDE]({commit, dispatch}: { commit: any, dispatch: any },index:number) {
      commit(SELECT_SLIDE,index)
    },
    async [DOCUMENT_TO_LLM]({commit, dispatch}: { commit: any, dispatch: any }, {file,ext}:{file:Blob,ext:string}) {
      const formData = new FormData();
      formData.append('slides', file);
      commit(SLIDES_TO_LLM_IN_PROGRESS,true)
      await axios({
        url: SLIDE_API + '/documents2LLM',
        params: {ext: ext},
        method: 'POST',
        data: formData,
        headers: {
          'Content-Type': 'multipart/form-data',
          'Content-Disposition': 'form-data; name=slides',
        },
      }).then(async (resp) => {
        const jobID=resp.data;
        // now wait the slide proceed by llm
        const DELAY=2000;
        let llmResponse="";
        while(!llmResponse){
          await delay(DELAY);
          llmResponse=await dispatch(SLIDES_TO_LLM_RESPONSE,jobID)
        }
        commit(UPDATE_SLIDES,llmResponse)
        commit(SELECT_SLIDE,0)
        commit(SLIDES_TO_LLM_IN_PROGRESS,false)
        return llmResponse;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        commit(SLIDES_TO_LLM_IN_PROGRESS,false)
        throw err;
      });
    },
    [UPDATE_SLIDES]({commit, dispatch}: { commit: any, dispatch: any },slides:Slide[]) {
      commit(UPDATE_SLIDES,slides)
    },
    [SLIDES_TO_LLM_IN_PROGRESS]({commit, dispatch}: { commit: any, dispatch: any },value:boolean) {
      commit(SLIDES_TO_LLM_IN_PROGRESS,value)
    },
    async [SLIDES_TO_LLM_RESPONSE]({commit, dispatch}: { commit: any, dispatch: any }, jobID:string) {
      return await axios({
        url: SLIDE_API + '/slides2LLM/'+jobID,
        method: 'GET',
      }).then((resp) => {
        const llmData=resp.data;
        // now wait the slide proceed by llm
        if(llmData==="ERROR"){
          throw new Error("Impossible to proceed this documents")
        }
        if(llmData){
          // decode base64 withtout issue utf-8 issue
          const decodedString = new TextDecoder('utf-8').decode(Uint8Array.from(atob(llmData), c => c.charCodeAt(0)));
          const jsonObject = JSON.parse(decodedString);
          return jsonObject
        }
        return
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        throw err;
      });
    },
    async [SLIDE_TO_LLM]({commit, dispatch}: { commit: any, dispatch: any },
                         {slides,hiddenB64Instruction,lang}:
                             {slides:Slide[],hiddenB64Instruction:string|null,
                               lang:string|null}) {
      commit(SLIDES_TO_LLM_IN_PROGRESS,true)
      store.dispatch(OPEN_AVATAR_EDITING_CONTENT, true)
      const slidesClean=clone(slides) as Slide[]
      for(const slide of slidesClean){
        slide.imageB64="";
      }
      await axios({
        url: SLIDE_API + '/slides2LLM',
        method: 'POST',
        data: {slides:slidesClean,
          customInstructionB64:hiddenB64Instruction,
        lang:lang},
      }).then(async (resp) => {
        const jobID=resp.data;
        // now wait the slide proceed by llm
        const DELAY=2000;
        let llmResponse:Slide[]|undefined=undefined;
        while(!llmResponse){
          await delay(DELAY);
          llmResponse=await dispatch(SLIDES_TO_LLM_RESPONSE,jobID) as Slide[]
        }
        // now reapply the image of slides
        for(const slide of llmResponse){
          slide.imageB64=slides.find((s)=>s.order===slide.order)?.imageB64||"";
        }
        commit(UPDATE_SLIDES,llmResponse)
        commit(SELECT_SLIDE,0)
        commit(SLIDES_TO_LLM_IN_PROGRESS,false)
        return llmResponse;
      }).catch((err) => {
        if (err.data) {
          err = err.data;
        }
        commit(SLIDES_TO_LLM_IN_PROGRESS,false)
        throw err;
      });

    }
  },
};
