// MEDIA
export const CREATE_MEDIA = 'CREATE_MEDIA';
export const CREATE_CHUNK_MEDIA = 'CREATE_CHUNK_MEDIA';
export const MEDIA_UPDATE_MEDIAID = 'MEDIA_UPDATE_MEDIAID';
export const SET_BLOB_TO_MEDIA = 'SET_BLOB_TO_MEDIA';

export const SEND_MEDIA = 'SEND_MEDIA';
export const SEND_CHUNK_MEDIA = 'SEND_CHUNK_MEDIA';
export const SEND_VIRTUAL_MEDIA = 'SEND_VIRTUAL_MEDIA';
export const DOWNLOAD_MEDIA = 'DOWNLOAD_MEDIA';

export const TEXT_2_SPEECH_GENERATE="TEXT_2_SPEECH_GENERATE"
export const SRT_2_SPEECH_GENERATE = "SRT_2_SPEECH_GENERATE"
export const VOICE_OVER_MEDIA="VOICE_OVER_MEDIA"
export const CLEAR_MEDIA = 'CLEAR_MEDIA';
export const ADD_MEDIA = 'ADD_MEDIA';
export const SET_MEDIA_LIST = 'SET_MEDIA_LIST';
export const DELETE_MEDIA = 'DELETE_MEDIA';

export const ADD_TO_MEDIA_CHUNK_QUEUE = 'ADD_TO_MEDIA_CHUNK_QUEUE';
export const REMOVE_MEDIA_FROM_QUEUE = 'REMOVE_MEDIA_FROM_QUEUE';

export const REFRESH_MEDIA = 'REFRESH_MEDIA';

export const VALIDATE_MEDIA = 'VALIDATE_MEDIA';
export const METADATA_MEDIA = 'METADATA_MEDIA';
export const UPDATE_METADATA="UPDATE_METADATA";
