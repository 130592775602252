import {
    CHANGE_PLAYER_SPEED, CHANGE_PLAYER_VOLUME, EXPERIMENTAL_FEATURE,
    FIRST_WEET_CONGRAT, ONBOARDING_PLAYER_1,
    ONBOARDING_RECORDER_1, ONBOARDING_SECTION_1,
    RESET_PERSIST_STORE, SAVE_AVATAR, SAVE_AVATAR_BACKGROUND, SAVE_AVATAR_LANG, SAVE_AVATAR_VOICE, SELECT_WORKSPACE,
    SET_LAST_NEWS_DATE,
    SET_LAST_VERSION_NEWS, SETUP_FROM_SPEACH_TOKEN, STORE_LINK_REDIRECT_AFTER_PAYMENT,
    SUBTITLE_LANG, SUBTITLE_VOICE_OVER_LANG, TIME_LINE_VISU,
} from '@/store/persistedInformation/persistAction';
import {jwtDecode} from "jwt-decode";

export default {
    state: {
        subtitleLang: "",
        subtitleVoiceOverLang: "",
        lastDateNewsCheck: new Date().getTime(),
        lastVersionNewsCheck: 2.20,
        first_weet_congrat: false,
        onboarding_recorder1: false,
        onboarding_player1: false,
        onboarding_section1: false,
        experimentalFeature: false,
        timelineVisu: false,
        selectedWorkspaceID: '',

        // player config
        volume: 100,
        speedMode: 1.0,
        linkToRedirectAfterPaymentSuccess:"",

        // speach config
        autoplay:null,
        avatarAI:null,
        avatarAIremaining:null,
        transcript:null,
        subtitleVoiceGeneration:null,
        voiceOverAI:null,
        lang:null,

        // avatar
        avatar:null,
        avatarBackground:null,
        avatarVoice:null,
        avatarLang:null

    },
    mutations: {
        [RESET_PERSIST_STORE]: (state: any, isoCode: string) => {
            // state.transcriptLang = isoCode;
            state.selectedWorkspaceID = '';
            state.linkToRedirectAfterPaymentSuccess='';
        },
        [SUBTITLE_LANG]: (state: any, value: boolean) => {
            state.subtitleLang = value;
        },
        [SUBTITLE_VOICE_OVER_LANG]: (state: any, value: boolean) => {
            state.subtitleVoiceOverLang = value;
        },
        [SET_LAST_NEWS_DATE]: (state: any, value: Date) => {
            state.lastDateNewsCheck = value.getTime();
        },
        [SET_LAST_VERSION_NEWS]: (state: any, value: number) => {
            state.lastVersionNewsCheck = value;
        },
        [FIRST_WEET_CONGRAT]: (state: any, value: boolean) => {
            state.first_weet_congrat = value;
        },
        [ONBOARDING_RECORDER_1]: (state: any, value: boolean) => {
            state.onboarding_recorder1 = value;
        },
        [ONBOARDING_PLAYER_1]: (state: any, value: boolean) => {
            state.onboarding_player1 = value;
        },
        [ONBOARDING_SECTION_1]: (state: any, value: boolean) => {
            state.onboarding_section1 = value;
        },
        [EXPERIMENTAL_FEATURE]: (state: any, value: boolean) => {
            state.experimentalFeature = value;
        },
        [TIME_LINE_VISU]: (state: any, value: boolean) => {
            state.timelineVisu = value;
        },
        [SELECT_WORKSPACE]: (state: any, workspaceID: string) => {
            state.selectedWorkspaceID = workspaceID;
        },
        [CHANGE_PLAYER_SPEED]: (state: any, value: number) => {
            state.speedMode = value;
        },
        [CHANGE_PLAYER_VOLUME]: (state: any, value: number) => {
            state.volume = value;
        },
        [SAVE_AVATAR]: (state: any, value: number) => {
            state.avatar = value;
        },
        [SAVE_AVATAR_VOICE]: (state: any, value: number) => {
            state.avatarVoice = value;
        },
        [SAVE_AVATAR_BACKGROUND]: (state: any, value: number) => {
            state.avatarBackground = value;
        },
        [SAVE_AVATAR_LANG]: (state: any, value: number) => {
            state.avatarLang = value;
        },
        [STORE_LINK_REDIRECT_AFTER_PAYMENT]: (state: any, link: string) => {
            state.linkToRedirectAfterPaymentSuccess = link;
        },
        [SETUP_FROM_SPEACH_TOKEN]:(state:any,{
           autoplay,
            lang,
            avatarAI,
            avatarAIremaining,
            transcript,
            subtitleVoiceGeneration,
            voiceOverAI,
        })=>{
            state.autoplay=autoplay;
            state.avatarAI= avatarAI;
            state.avatarAIremaining=avatarAIremaining;
            state.transcript=transcript;
            state.subtitleVoiceGeneration=subtitleVoiceGeneration;
            state.voiceOverAI=voiceOverAI;
            state.lang=lang;
        },
    },
    getters: {
        isAutoPlay:(state:any)=>{
            // pan pan tu es un boolean
            return !!state.autoplay
        },
        isAvatarAI:(state:any)=>{
            if(state.avatarAI===null ||
            state.avatarAI===undefined){
                return true;
            }else{
                return state.avatarAI;
            }
        },
        getAvatarAIremaining:(state:any)=>{
            if(state.avatarAIremaining===null ||
                state.avatarAIremaining===undefined){
                return 3600; // One hour is no setup to avoid abusing
            }else{
                return state.avatarAIremaining;
            }
        },
        isTranscript:(state:any)=>{
            if(state.transcript===null ||
                state.transcript===undefined){
                return true;
            }else{
                return state.transcript;
            }
        },
        isSubtitleVoiceGeneration:(state:any)=>{
            if(state.subtitleVoiceGeneration===null ||
                state.subtitleVoiceGeneration===undefined){
                return true;
            }else{
                return state.subtitleVoiceGeneration;
            }
        },
        isVoiceOverAI:(state:any)=>{
            if(state.voiceOverAI===null ||
                state.voiceOverAI===undefined){
                return true;
            }else{
                return state.voiceOverAI;
            }
        },
        getLang:(state:any)=>{
            if(state.lang===null ||
                state.lang===undefined){
                return "en";
            }else{
                return state.lang;
            }
        },
        getSubtitleLang: (state: any): boolean => {
            return state.subtitleLang;
        },
        getSubtitleVoiceOverLang:(state:any): boolean=>{
            return state.subtitleVoiceOverLang;
        },
        isExperimentalFeature: (state: any): boolean => {
            return state.experimentalFeature;
        },
        getSelectedWorkspaceID: (state: any): string | null => {
            return state.selectedWorkspaceID;
        },
        getLastDateNewsCheck: (state: any): number => {
            return state.lastDateNewsCheck;
        },
        getLastVersionNewsCheck: (state: any): number => {
            return state.lastVersionNewsCheck;
        },
        speedMode: (state: any): number => {
            return state.speedMode;
        },
        isFirstWeetCreated: (state: any): boolean => {
            return state.first_weet_congrat;
        },
        isOnboardingRecorder1: (state: any): boolean => {
            return state.onboarding_recorder1;
        },
        isOnboardingSection1: (state: any): boolean => {
            return state.onboarding_section1;
        },
        isOnboardingPlayer1: (state: any): boolean => {
            return state.onboarding_player1;
        },
        isTimeLineVisu: (state: any): boolean => {
            return state.timelineVisu;
        },
        volume: (state: any): number => {
            return state.volume;
        },
        getLinkToRedirectAfterPaymentSuccess:(state:any):string=>{
            return state.linkToRedirectAfterPaymentSuccess;
        },
        getFavAvatar:(state:any):string=>{
            return state.avatar;
        },
        getFavAvatarVoice:(state:any):string=>{
            return state.avatarVoice;
        },
        getFavAvatarLang:(state:any):string=>{
            return state.avatarLang;
        },
        getFavAvatarBackground:(state:any):string=>{
            return state.avatarBackground;
        }
    },
    actions: {
        [STORE_LINK_REDIRECT_AFTER_PAYMENT]({commit, dispatch}: { commit: any, dispatch: any },
                                            link: string){
            commit(STORE_LINK_REDIRECT_AFTER_PAYMENT,link);
        },
        [RESET_PERSIST_STORE]({commit, dispatch}: { commit: any, dispatch: any }) {
            commit(RESET_PERSIST_STORE);
        },
        [SUBTITLE_LANG]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(SUBTITLE_LANG, value);
        },
        [SUBTITLE_VOICE_OVER_LANG]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(SUBTITLE_VOICE_OVER_LANG, value);
        },
        [EXPERIMENTAL_FEATURE]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(EXPERIMENTAL_FEATURE, value);
        },
        [SET_LAST_NEWS_DATE]({commit, dispatch}: { commit: any, dispatch: any }, value: Date) {
            commit(SET_LAST_NEWS_DATE, value);
        },
        [SET_LAST_VERSION_NEWS]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
            commit(SET_LAST_VERSION_NEWS, value);
        },
        [CHANGE_PLAYER_SPEED]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
            commit(CHANGE_PLAYER_SPEED, value);
        },
        [FIRST_WEET_CONGRAT]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(FIRST_WEET_CONGRAT, value);
        },
        [ONBOARDING_RECORDER_1]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(ONBOARDING_RECORDER_1, value);
        },
        [ONBOARDING_PLAYER_1]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(ONBOARDING_PLAYER_1, value);
        },
        [ONBOARDING_SECTION_1]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(ONBOARDING_SECTION_1, value);
        },
        [TIME_LINE_VISU]({commit, dispatch}: { commit: any, dispatch: any }, value: boolean) {
            commit(TIME_LINE_VISU, value);
        },
        [SELECT_WORKSPACE]({commit, dispatch}: { commit: any, dispatch: any }, workspaceID: string) {
            commit(SELECT_WORKSPACE, workspaceID);
        },
        [CHANGE_PLAYER_VOLUME]({commit, dispatch}: { commit: any, dispatch: any }, value: number) {
            commit(CHANGE_PLAYER_VOLUME, value);
        },
        [SAVE_AVATAR]({commit, dispatch}: { commit: any, dispatch: any }, value: string) {
            commit(SAVE_AVATAR, value);
        },
        [SAVE_AVATAR_VOICE]({commit, dispatch}: { commit: any, dispatch: any }, value: string) {
            commit(SAVE_AVATAR_VOICE, value);
        },
        [SAVE_AVATAR_BACKGROUND]({commit, dispatch}: { commit: any, dispatch: any }, value: string) {
            commit(SAVE_AVATAR_BACKGROUND, value);
        },
        [SAVE_AVATAR_LANG]({commit, dispatch}: { commit: any, dispatch: any }, value: string) {
            commit(SAVE_AVATAR_LANG, value);
        },
        [SETUP_FROM_SPEACH_TOKEN]({commit,dispatch}:{commit:any,dispatch:any},token:string){
            const decodeJwt = jwtDecode(token);
            commit(SETUP_FROM_SPEACH_TOKEN, {
                autoplay: decodeJwt['autoplay'],
                lang:decodeJwt['lang'],
                avatarAI: decodeJwt['avatarAI'],
                avatarAIremaining:decodeJwt['avatarAIremaining'],
                transcript: decodeJwt['transcript'],
                subtitleVoiceGeneration: decodeJwt['subtitleVoiceGeneration'],
                voiceOverAI:decodeJwt['voiceOverAI'],
            })
        }
    },
};
