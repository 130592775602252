<template>
  <div>
    <transition name="zoom" >
      <w-button v-show="mustShow"  data-test="recorderStartPauseButton"
                icon="book-open-variant-outline" v-if="!isOpenAvatarEditingContent" :enabled="isButtonEnabled"
                :tooltip="tooltip" @click="openAvatarContent">
        {{$t('createWeetComponent.record.button.writeScript')}}
      </w-button>
    </transition>
    <transition name="zoom">
      <div v-show="slides && slides.length>0" class="slidesControlBar" v-if="currentSlide">
          <w-button class="buttonPrev" icon="chevron-left"  color="outlined"
                    size="small" @click="prevSlide"
                    :enabled="currentSlide && currentSlide.order>1"></w-button>

        {{currentSlide.order}} / {{slides.length}}

        <w-button class="buttonNext" icon="chevron-right" color="outlined"
                  size="small"  @click="nextSlide"
                  :enabled="currentSlide && currentSlide.order<slides.length"></w-button>
      </div>

    </transition>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import WButton from '@/components/wrapper/w-button.vue';
import {OPEN_AVATAR_EDITING_CONTENT} from "@/store/AIAvatar/AIAVatarAction";
import {Slide} from "@/store/slides2Video/slides2VideoModels";
import {SELECT_SLIDE} from "@/store/slides2Video/slides2VideoAction";

@Component({
  components: {WButton},
})
export default class GenerateAvatarButton extends Vue {
  private mustShow: boolean = false;

  get isOpenAvatarEditingContent(){
    return store.getters.isOpenAvatarEditionContent;
  }

  get slides(){
    return store.getters.slides;
  }

  get currentSlide(): Slide | null {
    if (store.getters.getCurrentSlideIndex > -1) {
      return this.slides[store.getters.getCurrentSlideIndex]
    }
    return null;
  }
  get timeRemaining(){
    return store.getters.getAvatarAIremaining;
  }
  get isAIAvatarAuthorized(){
    return store.getters.isAvatarAI;
  }

  get isButtonEnabled():boolean{

    if(!this.isAIAvatarAuthorized){
      return false;
    }
    if(this.timeRemaining<=0){
      return false;
    }

    return true;
  }

  get tooltip(){
    if(!this.isAIAvatarAuthorized){
      return this.$t('createWeetComponent.aiAvatar.disabled')
    }
    if(this.timeRemaining<=0){
      return this.$t('createWeetComponent.aiAvatar.timeRemaining')
    }
    return "";
  }
  private mounted() {
    this.mustShow = true;
  }

  private prevSlide(){
    if(this.currentSlide){
      const index=this.currentSlide.order-2;
      store.dispatch(SELECT_SLIDE,index);
    }else {
      store.dispatch(SELECT_SLIDE, 0);
    }
  }

  private nextSlide(){
    if(this.currentSlide){
      const index=this.currentSlide.order;
      store.dispatch(SELECT_SLIDE,index);
    }else {
      store.dispatch(SELECT_SLIDE, 0);
    }
  }
  private openAvatarContent(){
    store.dispatch(OPEN_AVATAR_EDITING_CONTENT,true)
  }
}
</script>
<style scoped lang="scss">
.slidesControlBar{
  display: flex;
  justify-items: center;
  align-items: center;

  .buttonPrev{
    margin-right: 32px;
  }
  .buttonNext{
    margin-left: 32px;
  }
}
</style>
