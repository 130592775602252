<template>
  <div class="buttonContainer" data-test="recorderShareScreenArea">
    <!-- AI Avatar-->
    <div  data-test="aiAvatarChooseButton" class="buttonSelector" :class="isAIAvatarChoose?'selected':''"
          @click="chooseAIAvatar">
      <w-icon class="iconSelector" icon="account-outline"/>
      <div class="labelButtonSelector">{{ $t('screenLayoutSelector.aiAvatar') }}</div>
      <div class="betaInfo" v-if="false">{{$t('generic.beta')}}</div>
    </div>
    <!-- Background Button -->
    <div data-test="virtualScreenChooseButton" class="buttonSelector" :class="isVirtualScreenCastChoose?'selected':''"
         @click="chooseVirtualScreenCast">
      <w-icon class="iconSelector" icon="card-account-details-outline"/>
      <div class="labelButtonSelector">{{ $t('screenLayoutSelector.scene') }}</div>
    </div>
    <!-- screencast Button -->
    <div data-test="screenCastChooseButton" class="buttonSelector" :class="isScreenCastChoose?'selected':''"
         @click="chooseScreenCast">
      <w-icon class="iconSelector" icon="monitor"/>
      <w-icon class="avatarIconSelector" icon="account" size="small"/>
      <div class="labelButtonSelector">{{ $t('screenLayoutSelector.screen') }}</div>
    </div>
    <!-- Webcam button -->
    <div data-test="webcamOnlyChooseButton" class="buttonSelector" :class="isWebcamChoose?'selected':''"
         @click="chooseWebcamFull">
      <w-icon class="iconSelector" icon="webcam"/>
      <div class="labelButtonSelector">{{ $t('screenLayoutSelector.webcam') }}</div>
    </div>
    <!-- UPLOAD Button -->
    <div v-if="isInPremiumWorkspace || true">
      <b-upload @input="chooseFile" accept="video/*" v-if="!isUploadChoose">
        <div data-test="uploadChooseButton" class="buttonSelector">
          <w-icon class="iconSelector" icon="upload"/>
          <div class="labelButtonSelector">{{ $t('screenLayoutSelector.upload') }}</div>
        </div>
      </b-upload>
    </div>
    <div v-else>
      <div data-test="uploadChooseButton" class="buttonSelector" @click="moveOrGoToPayment()">
        <w-icon class="iconSelector" icon="upload"/>
        <div class="labelButtonSelector">{{ $t('screenLayoutSelector.upload') }}</div>
        <UpgradePlanTag class="upgradePlanTag" size="small"/>
      </div>
      <WorkspaceMovePanel :weet="myWeet" :active.sync="moveWeetPanel" :change-workspace-on-select="true"/>
    </div>

  </div>
</template>

<script lang="ts">
import {Component, Vue} from 'vue-property-decorator';
import WButton from '@/components/wrapper/w-button.vue';
import store from '@/store';
import {
  RECORD_MAIN_VIDEO_TYPE,
  RECORD_MAIN_VIRTUAL_BACKGROUND,
  RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_MUTE,
  RECORD_STICKER_VIDEO_MUTE,
  RECORD_STICKER_VIDEO_TYPE
} from '@/store/recordingState/recordStateAction';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import randomInt from 'random-int';
import {listOfAnonymeScreenVirtualBackground} from '@/utils/filterUtils';
import WIcon from '@/components/wrapper/w-icon.vue';
import {confirmation, inform} from '@/utils/dialog';
import {PaymentPlanEnum} from "@/store/workspace/workspaceModel";
import UpgradePlanTag from "@/components/upgradePlan/UpgradePlanTag.vue";
import {canMoveWeetFromCurrentWorkspace, hasPaidWorkspace} from "@/utils/workspaceUtil";
import router from "@/router";
import WorkspaceMovePanel from "@/components/workspace/WorkspaceMovePanel.vue";
import {Weet} from "@/store/weet/weetModel";
import {STORE_LINK_REDIRECT_AFTER_PAYMENT} from "@/store/persistedInformation/persistAction";


@Component({
  components: {WorkspaceMovePanel, UpgradePlanTag, WIcon, WButton},
})
export default class ScreenLayoutSelector extends Vue {

  private moveWeetPanel: boolean = false;


  get selectedWorkspaceID(): string {
    return store.getters.getSelectedWorkspace;
  }

  get myWeet(): Weet | null {
    return store.getters.getMyWeet(store.getters.getEditingWeetID) || null;
  }


  get isInPremiumWorkspace(): boolean {
    var currentWorkspace = store.getters.getSelectedWorkspace;
    if (currentWorkspace) {
      if (currentWorkspace) {
        return currentWorkspace.plan !== PaymentPlanEnum.FREE;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  get isRecording(): boolean {
    return !store.getters.isStartRecordingAivailable;
  }

  get isScreenCastChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.SCREENCAST;
  }

  get isUploadChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.UPLOAD;
  }

  get isVirtualScreenCastChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.VIRTUAL_SCREENCAST;
  }

  get isNoneChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.NONE;
  }

  get isWebcamChoose(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.WEBCAM;
  }

  get isAIAvatarChoose():boolean{
    return store.getters.getMainRecordSource===SourceVideoType.AI_AVATAR
  }

  get canMoveWeet(): boolean {
    return canMoveWeetFromCurrentWorkspace();
  }

  private async moveOrGoToPayment() {
    // if i'm in PS
    // and i have a paid workspace
    // and i can move my weet
    if (!this.selectedWorkspaceID && hasPaidWorkspace() && this.canMoveWeet) {
      await confirmation(
          this.$t('screenLayoutSelector.uploadPremium.move.description').toString(),
          this.$t('screenLayoutSelector.uploadPremium.move.title').toString(),
      );
      this.moveWeetPanel = true;
    } else {
      try {
        await confirmation(this.$t('screenLayoutSelector.uploadPremium.close.description').toString(),
            this.$t('screenLayoutSelector.uploadPremium.close.title').toString());
        this.$emit('close');
        store.dispatch(STORE_LINK_REDIRECT_AFTER_PAYMENT,document.location.href)
        router.push({name: 'MyWorkspacePayment'});
      } catch (e) {
        //nothing to do; the user don't want to quit the edit
      }
    }

  }

  public chooseFile(file: File) {
    if (file) {
      this.$emit('input', file);
      store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.NONE);
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
        store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.UPLOAD);
      });
    }
  }

  public chooseAIAvatar(){
    if (this.isRecording || this.isAIAvatarChoose) {
      return;
    }

    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.NONE);

    // remove sceeen if already and ask for another screen
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.AI_AVATAR);
      // remove fullscreen if need
      if (store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });
  }

  public chooseWebcamFull() {
    if (this.isRecording || this.isWebcamChoose) {
      return;
    }
    if (store.getters.isStickerMute) {
      inform(this.$t('stickers.button.micro.toast.on').toString(), 'top', 'light');
      store.dispatch(RECORD_STICKER_MUTE);
    }
    if (store.getters.isStickerVideoMute) {
      inform(this.$t('stickers.button.webcam.toast.on').toString(), 'top', 'light');
      setTimeout(() => {
        store.dispatch(RECORD_STICKER_VIDEO_MUTE);
      }, 100);
    }

    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.NONE);

    // remove sceeen if already and ask for another screen
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.WEBCAM);
      // remove fullscreen if need
      if (store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });
  }

  public chooseScreenCast() {
    if (this.isRecording) {
      return;
    }
    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.WEBCAM);
    // remove sceeen if already and ask for another screen
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.SCREENCAST);
      // remove fullscreen if need
      if (store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });
  }

  public chooseVirtualScreenCast() {
    if (this.isRecording) {
      return;
    }
    const listOfBackground = listOfAnonymeScreenVirtualBackground();
    const value = listOfBackground[randomInt(listOfBackground.length - 1)].value;
    store.dispatch(RECORD_MAIN_VIRTUAL_BACKGROUND, value);
    setActionEvent(ActionEvent.record_virtual_screen, {virtual_screen_background: value});

    store.dispatch(RECORD_STICKER_VIDEO_TYPE, SourceVideoType.WEBCAM);

    // remove sceeen if already and ask for another screen
    store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.NONE).then(() => {
      store.dispatch(RECORD_MAIN_VIDEO_TYPE, SourceVideoType.VIRTUAL_SCREENCAST);
      // put fullscreen if need
      if (!store.getters.isStickerFullscreen) {
        store.dispatch(RECORD_STICKER_FULLSCREEN);
      }
    });
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.buttonContainer {
  display: flex;
  width: 100%;
  justify-content: center;

  .buttonSelector {
    width: 100px;
    margin-left: 8px;
    margin-right: 8px;
    border-radius: 8px;
    padding: 8px;
    background: white;
    cursor: pointer;
    border: solid 1px var(--light1);
    position: relative;

    .upgradePlanTag {
      position: absolute;
      bottom: -8px;
      right: -8px;
    }

    .iconSelector {
      color: var(--dark);
    }

    .avatarIconSelector {
      position: absolute;
      bottom: 33px;
      left: 32px;
      color: var(--dark);
      background: white;
      border-radius: 50%;
    }

    .labelButtonSelector {
      font-size: 14px;
      color: var(--dark);
    }
    .betaInfo{
      position: absolute;
      font-size: 12px;
      width: 50%;
      left: 25%;
      border-radius: 8px;
      color: white;
      background: var(--secondary);
    }
    &.selected {
      background: var(--light);
      border: solid 1px var(--light2);
      //  @extend .insetShadow;
      .avatarIconSelector {
        background: var(--light);
      }
    }

    &:hover {
      background: var(--light1);

      .avatarIconSelector {
        background: var(--light1);
      }

      .iconSelector {
      }

      .labelButtonSelector {
      }
    }
  }
}
</style>
