<template>
  <div class="weetAdvancedEditingPreviewContainer" >
    <div class="playerContainer"
         :class="{customMaxHeight:forceMaxHeight>0,modeIntegration:modeIntegration}"
         :style="forceMaxHeight>0?'height:'+maxHeight+';max-height:'+maxHeight+';':''"
         ref="playerPreviewScreen">
      <WeetPlayer  :miniSticker="true" :editMode="true" :maxHeight="maxHeight"
                  :fullResponsive="true"
                  :emote-aivailable="false"
                  :fullScreenElement="'playerPreviewScreen'"
                  :weetID="weetID" :audio-url="urlAudioToSync"
                  :enabled-chapter="false" :sectionSelected="sectionSelected"/>
    </div>
    <!-- SELECTOR -->
    <div class="weetAdvancedEditingPanel">
      <CreateWeetCutMode :weet="myWeet" v-if="isCutMode && !isProcessing" @back="back"
                         :mode-integration="modeIntegration"/>
      <CreateWeetChapterMode :weet="myWeet" v-if="isChapterMode  && !isProcessing" @back="back"
                             :mode-integration="modeIntegration"/>
      <CreateWeetThumbnailMode :weet="myWeet" v-if="isThumbnailMode  && !isProcessing" @back="back"
                               :mode-integration="modeIntegration"/>
      <CreateWeetInteractionMode :weet="myWeet" v-if="isInteractionMode  && !isProcessing" @back="back"
                                 :mode-integration="modeIntegration"/>
      <CreateWeetVoiceOverMode :weet="myWeet" v-if="isVoiceOverMode  && !isProcessing" @back="back"
                               :mode-integration="modeIntegration"
                               @onSectionSelected="onSectionSelected"
                               @onAudioToSync="onAudioToSync"/>
      <CreateWeetSubtitleMode :weet="myWeet" v-if="isSubtitleMode  && !isProcessing" @back="back"
                              :mode-integration="modeIntegration"/>
    </div>
    <w-button icon="check" class="buttonSave" :class="{modeIntegration:modeIntegration}"
              :loading="!isSelectorAivailable"
              @click="saveModification" :enabled="timeLineModified"
              v-if="isChapterMode || isCutMode">
      <span v-if="saveButtonLabel">
        {{ saveButtonLabel }}
      </span>
      <span v-else>
      {{ $t('createWeetComponent.advancedEditing.validate') }}
        </span>
    </w-button>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
import WeetPlayer from '@/components/play/player/WeetPlayer.vue';
import store from '@/store';
import WInput from '@/components/wrapper/w-input.vue';
import WError from '@/components/wrapper/w-error.vue';
import {Weet} from '@/store/weet/weetModel';
import WButton from '@/components/wrapper/w-button.vue';
import EndWeetButton from '@/components/actionButton/EndWeetButton.vue';
import TimeLine from '@/components/recorder/timeLine/TimeLine.vue';
import {createUnsavedEditingWeet, isWeetIsReady} from '@/utils/weetUtil';
import TimeLineSelector from '@/components/recorder/timeLine/TimeLineSelector.vue';
import {
  ATTACHED_TIMELINE_TO_WEET,
  AttachedTimeLineParams,
  CUT_TIMELINE_WEET,
  CutTimeLineParams,
  REFRESH_WEET_FOR_EDITING
} from '@/store/myWeet/myWeetAction';
import delay from 'delay';
import {TIMELINE_ADVANCED_MODIFIED} from '@/store/advancedEditing/advancedEditingAction';
import {PLAYER_PLAYING, SEEK_PLAYER, TIMER_PLAYER} from '@/store/timeLine/timeLineAction';
import ProgressProcessPlayer from '@/components/play/process/ProgressProcessPlayer.vue';
import CreateWeetCutMode from '@/components/recorder/createWeetSubComponent/advancedMode/CreateWeetCutMode.vue';
import CreateWeetChapterMode from '@/components/recorder/createWeetSubComponent/advancedMode/CreateWeetChapterMode.vue';
import CreateWeetThumbnailMode
  from '@/components/recorder/createWeetSubComponent/advancedMode/CreateWeetThumbnailMode.vue';
import {confirmationAlert} from "@/utils/dialog";
import CreateWeetInteractionMode
  from "@/components/recorder/createWeetSubComponent/advancedMode/CreateWeetInteractionMode.vue";
import CreateWeetVoiceOverMode
  from "@/components/recorder/createWeetSubComponent/advancedMode/CreateWeetVoiceOverMode.vue";
import {Segment} from "@/store/timeLine/timeEvent";
import CreateWeetSubtitleMode
  from "@/components/recorder/createWeetSubComponent/advancedMode/CreateWeetSubtitleMode.vue";
import {op} from "@tensorflow/tfjs";

@Component({
  components: {
    CreateWeetSubtitleMode,
    CreateWeetVoiceOverMode,
    CreateWeetInteractionMode,
    CreateWeetThumbnailMode,
    CreateWeetChapterMode,
    CreateWeetCutMode,
    ProgressProcessPlayer, TimeLineSelector, TimeLine, EndWeetButton, WButton, WError, WInput, WeetPlayer
  },
})
export default class CreateWeetAdvancedEditingPreviewStep extends Vue {
  private isSelectorAivailable = true;

  @Prop({default: -1})
  private forceMaxHeight!: number;
  @Prop({default: null})
  private saveButtonLabel!: string | null;
  @Prop({default: false})
  private withoutProgressPanel!: boolean;
  @Prop({default: false})
  private modeIntegration!: boolean;

  private sectionSelected: Segment | null = null;
  private urlAudioToSync: string = '';
  private maxHeight = -1;

  private readonly HEIGHT_EDITING_PANEL = 300;


  get isCutMode(): boolean {
    return store.getters.isCutMode;
  }

  get isCutterMode(): boolean {
    return store.getters.isCutterMode;
  }

  get isChapterMode(): boolean {
    return store.getters.isChapterMode;
  }

  get isThumbnailMode(): boolean {
    return store.getters.isThumbnailMode;
  }

  get isInteractionMode(): boolean {
    return store.getters.isInteractionMode
  }

  get isVoiceOverMode(): boolean {
    return store.getters.isVoiceOverMode;
  }

  get isSubtitleMode(): boolean {
    return store.getters.isSubtitleMode;
  }

  get isMergeMode(): boolean {
    return store.getters.isMergeMode;
  }

  get weetID(): string {
    return store.getters.getEditingWeetID;
  }

  get timeLineModified(): boolean {
    return store.getters.isAdvancedEditingModified;
  }

  get myWeet(): Weet {
    return createUnsavedEditingWeet(this.weetID);
  }

  get isProcessing(): boolean {
    return !isWeetIsReady(this.myWeet);
  }


  private onSectionSelected(segment: Segment | null): void {
    this.sectionSelected = segment;
    if (segment) {
      store.dispatch(SEEK_PLAYER, segment.start.time).then(() => {
        store.dispatch(PLAYER_PLAYING, false);
      });
    } else {
      store.dispatch(SEEK_PLAYER, 100).then(() => {
        store.dispatch(PLAYER_PLAYING, false);
      });
    }
  }

  private onAudioToSync(url: string | null) {
    if (url) {
      this.urlAudioToSync = url;
    } else {
      this.urlAudioToSync = "";
    }
  }

  public mounted() {
    this.sectionSelected = null;
    this.computeMaxHeight()
    window.addEventListener("resize", this.computeMaxHeight);
  }

  private computeMaxHeight() {
    if (this.forceMaxHeight > 0) {
      this.maxHeight = this.forceMaxHeight;
    } else {
      let optimizeSize = window.innerHeight - this.HEIGHT_EDITING_PANEL;
      if (optimizeSize < 324) {
        optimizeSize = 324;
      }
      this.maxHeight = optimizeSize;
    }
  }

  private async saveModification() {
    if (store.getters.isCutMode) {
      if (this.myWeet.subtitles.length > 0) {
        try {
          await confirmationAlert(this.$t('createWeetComponent.advancedEditing.button.warningCut.message').toString(),
              this.$t('createWeetComponent.advancedEditing.button.warningCut.title').toString());
        } catch (e) {
          return;
        }
      }

      this.isSelectorAivailable = false;
      const params = new CutTimeLineParams(this.myWeet.weetID, this.myWeet.timeLine);
      store.dispatch(CUT_TIMELINE_WEET, params).then(async () => {
        store.dispatch(REFRESH_WEET_FOR_EDITING, this.weetID);
        store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
        // now we return in back;
        await delay(1000);
        this.back();
        this.isSelectorAivailable = true;


      });
    } else if (store.getters.isChapterMode) {
      const timeLineAttachedParams = new AttachedTimeLineParams(this.weetID, store.getters.getTimeEvent, []);
      await store.dispatch(ATTACHED_TIMELINE_TO_WEET, timeLineAttachedParams);
      store.dispatch(REFRESH_WEET_FOR_EDITING, this.weetID);
      store.dispatch(TIMELINE_ADVANCED_MODIFIED, false);
      // now we return in back;
      this.back();
    }
  }

  private back(): void {
    // now we return in back;
    this.$emit('back');
  }

  private beforeDestroy(): void {
    window.removeEventListener("resize", this.computeMaxHeight);
  }

}
</script>

<style scoped lang="scss">

$HEIGHT_EDITING_PANEL: 300px;  // should be the same value thant computeSize Method
.weetAdvancedEditingPreviewContainer {
  margin: auto;
  position: relative;
  height: 100%;

  .weetAdvancedEditingPanel {
    min-height: $HEIGHT_EDITING_PANEL;
    max-height: $HEIGHT_EDITING_PANEL;
    height: $HEIGHT_EDITING_PANEL;
  }

  .playerContainer {
    position: relative;
    margin: auto;
    background: var(--black);
    border-radius: 4px;
    min-height: 324px;
    height: calc(100% - $HEIGHT_EDITING_PANEL);
    max-height: calc(100% - $HEIGHT_EDITING_PANEL);


    &.customMaxHeight {
      height: auto;
      max-height: inherit;
    }

    &.modeIntegration {
      margin-bottom: 16px;
    }

    .progressWeet {
      display: flex;
      justify-items: center;
      align-content: center;
      height: 100%;
      width: 100%;
      padding: 64px;
    }
  }


  .backButton {
    position: absolute;
    top: 16px;
    left: 16px;
  }

  .timeLineView {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: calc(100% - 0px);
  }


  .buttonSave {
    position: absolute;
    bottom: 16px;
    right: 16px;

    &.modeIntegration {
      right: 0px;
    }
  }


  .titleToolMode {
    font-size: 24px;
    font-weight: 900;
    flex-grow: 1;
    color: var(--black);
    text-align: left;
    justify-content: center;
    line-height: 32px;
    margin-left: 16px;
    margin-right: 16px;
  }

  .timeLineSelection {
    //padding-left: 64px;
    //padding-right: 64px;
    //padding-top: 16px
  }
}
</style>
