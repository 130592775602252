<template>
  <transition name="fade">
    <div class="AiEditorContainer"
         v-show="streamLoading">
    <AiAvatarSelectionSource class="buttonSelectionBlock" />
      <div class="aiAvatarSelection">
        <!-- AVATAR ONLY LAYOUT -->
        <div class="fullscreenAvatar" style="" v-if="isAvatarOnly">
          <div class="avatarBackground" :style="'background-image:url('+backgroundSelected?.url+');'"></div>
          <div class="avatarPreview" :class="avatarSelectedClass"></div>
        </div>
        <!-- AVATAR BOTTOM LEFT LAYOUT -->
        <div class="bottomLeftAvatar" v-if="isAvatarBottomLeft || isVoiceOnly">
          <div class="uploadSlidePanel">
            <b-upload v-if="!currentSlide" drag-drop class="uploadHoverPanel" :class="{'centerLayout':isVoiceOnly}"
                      @input="uploadAPdf" accept=".pdf" :loading="isProceedSlideInProgress"
            :disabled="!isAvatarAvaialbleEnabled">
              <p class="uploadIcon" v-if="!currentSlide && !isProceedSlideInProgress">
                <w-icon size="large" icon="upload-outline" color="light2"/>
              </p>
              <p class="uploadLabelTitle" v-if="!currentSlide  && !isProceedSlideInProgress ">
                {{ $t('aiAvatar.uploadLabelTitle') }}</p>
              <p class="uploadLabelSubTitle" v-if="!currentSlide  && !isProceedSlideInProgress ">
                {{ $t('aiAvatar.uploadLabelSubTitle') }}</p>
            </b-upload>
            <div v-else>
              <img  alt="pdf uploaded" :src="currentSlideImage"
                                        style="max-width: 100%;max-height: 307px">
              <div class="deletePDFLayout" :class="{'centerLayout':isVoiceOnly}"
                  @click="deleteDocument">
                <div >
                  <w-icon icon="file-document-remove-outline" size="large"/>
                  <div>{{$t('aiAvatar.removeDocument')}}</div>
                </div>
              </div>
            </div>
          </div>
          <transition name="zoom">
            <div class="stickerAvatar" v-show="isAvatarBottomLeft">
              <div class="avatarBackground" :style="'background-image:url('+backgroundSelected?.url+')'"></div>
              <div class="avatarPreview" :class="avatarSelectedClass"></div>
            </div>
          </transition>
        </div>
        <!-- Slide Layout Selector -->
        <div class="avatarLayoutSelection">
          <b-tabs type="is-toggle" class="dark small" v-model="slideLayout"
          @input="onSlideLayoutUpdated">
            <b-tab-item value="AVATAR_BOTTOM_LEFT" :label="$t('aiAvatar.avatarDoc')"
                        icon="account-file-text"></b-tab-item>
            <b-tab-item value="VOICE_ONLY" icon="">
              <template #header>
                <w-icon icon="microphone-outline" size="default"></w-icon>
                <w-icon icon="file-document-outline" size="default" style="margin-left: -12px;"></w-icon>
                <span> {{ $t('aiAvatar.voiceOnly') }} </span>
              </template>
            </b-tab-item>
            <b-tab-item value="AVATAR_ONLY" :label="$t('aiAvatar.avatarOnly')" icon="account-outline"></b-tab-item>
          </b-tabs>
        </div>

        <!-- PANEL LOADING PDF/LLM -->
        <div class="loadingPDFPanel" v-if="isProceedSlideInProgress">
          <div class="labelLoadingPDFPanel"> {{ $t('aiAvatar.loadingPDF') }}</div>
          <b-progress class="progressBar" type="is-primary" size="is-small"/>
        </div>
      </div>

      <div class="aiAvatarContentEdition" v-if="isContentEditingOpen && !isProceedSlideInProgress">
          <AIAvatarContentEditionPanel ref="aiAvatarContentEdition" @generateAllSlide="generateAllSlide"
          @generateSlide="generatethisSlide"/>
      </div>

      <w-modal :active="showModalWaiting" :fullscreen="true" :modal="true">
        <CenterCenter>
        <div class="panelWaiting">
          <div class="centerPanelWaiting">
            <div class="waitingLabel">{{$t('aiAvatar.generatingSlide')}}</div>
            <b-progress class="progressWaiting" type="is-primary" show-value :value="nbSlideGenerated/totalSlideToGenerate*100">
              <div class="labelWaiting">{{nbSlideGenerated}} / {{totalSlideToGenerate}}</div>
            </b-progress>
            <div class="successFeedback" v-if="nbSlideGenerated===totalSlideToGenerate">
              <div class="iconCheck">
                <w-icon icon="check-circle-outline" color="primary" />
              </div>
              <div class="labelSuccess">
              {{$t('aiAvatar.successFeedback')}}
              </div>
            </div>
            <w-button v-if="nbSlideGenerated<totalSlideToGenerate" class="buttonCancel" @click="onCancelProcess" color="outlined"> {{$t('generic.cancel')}}</w-button>
            <w-button v-else class="buttonOk" color="primary" @click="finishProcess"> {{$t('generic.ok')}}</w-button>
          </div>
        </div>
        </CenterCenter>
      </w-modal>

    </div>
  </transition>

</template>

<script lang="ts">
import store from '@/store';
import Vue from 'vue';
import {Component, Watch} from 'vue-property-decorator';
import {SourceVideoType} from '@/enum/SourceVideoEnum';


import {
  CLEAR_MAIN_MEDIAID,
  CLEAR_STICKER_MEDIAID,
  GENERATE_MAIN_MEDIAID,
  GENERATE_STICKERS_MEDIAID,
  RECORD_STICKER_POSITION,
  RECORD_STICKER_VIDEO_MUTE,
} from '@/store/recordingState/recordStateAction';
import VideoFilePlayer from '@/components/recorder/videoLayout/videoPlayer/VideoFilePlayer.vue';
import WSelect from "@/components/wrapper/w-select.vue";
import WButton from "@/components/wrapper/w-button.vue";
import WInput from "@/components/wrapper/w-input.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import UpgradePlanButton from "@/components/upgradePlan/UpgradePlanButton.vue";
import {isFreemium, MAX_LENGTH_AIAVATAR_FREEMIUM, MAX_LENGTH_AIAVATAR_PREMIUM} from "@/utils/workspaceUtil";
import {
  AiavatarGenerationModel,
  AvatarBackground,
  AvatarStyle, LIST_OF_AVATAR,
  LIST_OF_BACKGROUND,
} from "@/store/AIAvatar/aiavatarGenerationModel";
import {initWeetEditing} from "@/utils/createWeetUtil";
import {ChunkMedia, Media} from "@/store/media/mediaModel";
import {CREATE_CHUNK_MEDIA, REFRESH_MEDIA, UPDATE_METADATA,} from "@/store/media/mediaAction";
import {SAVE_TIMELINE_IF_NEED, TIMELINE_UNSAVED} from "@/store/timeLine/timeLineAction";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import {alertErrorWithConfirmation, alertMessageCircle, confirmation} from "@/utils/dialog";
import WToolTip from "@/components/wrapper/w-toolTip.vue";

import {SpeachEvent, SpeachEventAvatarDuration, SpeachEventName} from "@/speach/speachEvent";
import delay from "delay";
import {ScriptSlideLayout, Slide} from "@/store/slides2Video/slides2VideoModels";
import {
  DOCUMENT_TO_LLM,
  RESET_SLIDES_2_VIDEO,
  SELECT_SLIDE,
  UPDATE_SLIDES
} from "@/store/slides2Video/slides2VideoAction";
import {
  AIAVATAR_GENERATE,
  AVATAR_EDITING_TEXT,
  AVATAR_GENERATING,
  OPEN_AVATAR_EDITING_CONTENT,
  SELECT_AI_AVATAR,
  SELECT_AI_BACKGROUND, SELECT_AI_LANG, SELECT_AI_VOICE,
  VIDEO_2_SLIDE_GENERATE
} from "@/store/AIAvatar/AIAVatarAction";
import {StickerPosition} from "@/enum/StickerPositionEnum";
import AIAvatarSelectionButton from "@/components/recorder/aiAvatar/buttons/AIAvatarSelectionButton.vue";
import AiAvatarSelectionSource from "@/components/recorder/aiAvatar/AiAvatarSelectionSource.vue";
import AIAvatarContentEditionPanel from "@/components/recorder/aiAvatar/panel/AIAvatarContentEditionPanel.vue";
import WModal from "@/components/wrapper/w-modal.vue";
import {clone} from "@/utils/cloneUtil";
import {updateCurrentSlide} from "@/utils/slides/slideUtil";
import {listOfVoice} from "@/utils/voiceUtil";

@Component({
  components: {
    WModal,
    AIAvatarContentEditionPanel,
    AiAvatarSelectionSource,
    AIAvatarSelectionButton,
    WToolTip, CenterCenter, UpgradePlanButton, WIcon, WInput, WButton, WSelect, VideoFilePlayer},
  computed: {},
})
export default class AIAvatarSource extends Vue {

  private styleSelected = AvatarStyle.STATIC
  private versionAvatar: number = 2;
  private slideLayout = ScriptSlideLayout.AVATAR_BOTTOM_LEFT;
  private showModalWaiting=false;
  private cancelProcess=false;

  // value if the stream is setup
  private streamLoading: boolean = false;

  private nbSlideGenerated=0;
  private totalSlideToGenerate=0;

  get langSelected(){
    return store.getters.getAvatarLangSelected
  }

  get isContentEditingOpen() {
    return store.getters.isOpenAvatarEditionContent
  }

  get isAvatarGenerating() {
    return store.getters.isAvatarGenerating
  }
  get avatarSelected(){
    return store.getters.getAvatarSelected
  }

  get maxlengthOfCharacter() {
    if (this.isFreemium) {
      return MAX_LENGTH_AIAVATAR_FREEMIUM;
    } else {
      return MAX_LENGTH_AIAVATAR_PREMIUM;
    }
  }

  get timeRemaining(){
    return store.getters.getAvatarAIremaining;
  }
  get isAIAvatarAuthorized(){
    return store.getters.isAvatarAI
  }

  get isAvatarAvaialbleEnabled():boolean{
    if(!this.isAIAvatarAuthorized){
      return false;
    }
    if(this.timeRemaining<=0){
      return false;
    }

    return true;
  }

  get backgroundSelected(){
    const bgSelected=store.getters.getAvatarBabckgroundSelected;
    return LIST_OF_BACKGROUND.find((bg)=>{
      return bg.background===bgSelected
    })
  }

  get avatarSelectedClass() {
    return this.avatarSelected + "_" + this.styleSelected;
  }

  get isAvatarBottomLeft() {
    return this.slideLayout === ScriptSlideLayout.AVATAR_BOTTOM_LEFT
  }

  get isAvatarOnly() {
    return this.slideLayout === ScriptSlideLayout.AVATAR_ONLY
  }

  get isVoiceOnly() {
    return this.slideLayout === ScriptSlideLayout.VOICE_ONLY
  }

  get voiceSelected(){
    return store.getters.getAvatarVoiceSelected
  }

  get textToGenerate(){
    return store.getters.getAiAvatarTextContent
  }

  get isFreemium(): boolean {
    return isFreemium()
  }


  get isProceedSlideInProgress() {
    return store.getters.isProceedSlideInProgress;
  }

  get slides(): Slide[] {
    return store.getters.slides;
  }

  get currentSlide(): Slide | null {
    if (store.getters.getCurrentSlideIndex > -1) {
      return store.getters.slides[store.getters.getCurrentSlideIndex];
    }
    return null;
  }

  get currentSlideImage(): string {
    if (this.currentSlide) {
      return "data:image/jpg;base64," + this.currentSlide.imageB64;
    } else {
      return "";
    }
  }

  private deleteDocument(){
    store.dispatch(UPDATE_SLIDES,[])
  }

  @Watch("currentSlide")
  private onCurrentSlideChange() {
    if (this.currentSlide) {
      this.slideLayout = this.currentSlide.layout;
      const defaultBackground=LIST_OF_BACKGROUND.find((bg)=>{
        return bg.background===AvatarBackground.WHITE
      })
      store.dispatch(SELECT_AI_BACKGROUND,this.currentSlide.avatarBackground||this.backgroundSelected?.background||defaultBackground);
      store.dispatch(SELECT_AI_AVATAR,this.currentSlide.avatar||this.avatarSelected)

      // fix current Slide (propagate previous selection if empty)
      // waiting all Watch finished
      this.$nextTick(()=>{
        if(!this.currentSlide){
          return;
        }
        let isModified=false;
        const newSlide=clone(this.currentSlide) as Slide;

        // propagate backgroudn if need
        if(!this.currentSlide.avatarBackground && this.backgroundSelected){
          newSlide.avatarBackground=this.backgroundSelected.background
          isModified=true;
        }
        // propagate avatar if need
        if(!this.currentSlide.avatar && this.avatarSelected){
          newSlide.avatar=this.avatarSelected;
          isModified=true;
        }
        // propagate lang if need
        if(!this.currentSlide.lang && this.langSelected){
          newSlide.lang=this.langSelected;
          isModified=true;
        }
        // propage voice
        if(!this.currentSlide.voice && this.voiceSelected){
          const gender=LIST_OF_AVATAR.find((av) => {
            return av.avatar === this.avatarSelected
          })?.gender;
          const listOfVoiceAvailable=listOfVoice.filter((voice) => {
            return voice.gender === gender
          });
          const isVoiceAvaialbleForGender=!!listOfVoiceAvailable.find((v)=>v.value===this.voiceSelected)
          if(isVoiceAvaialbleForGender) {
            newSlide.voice = this.voiceSelected;
          }else{
            newSlide.voice=listOfVoiceAvailable[0].value
          }

          isModified=true;
        }
        if(isModified){
        updateCurrentSlide(newSlide);
        }
      })
    }
  }

  @Watch("isContentEditingOpen")
  private onContentEditingOpen(){
    if(this.slides.length===0){
      this.slideLayout=ScriptSlideLayout.AVATAR_ONLY
    }
  }

  @Watch("backgroundSelected")
  private onBackgroundSelected(bg){
    if(this.currentSlide && bg){
      const newSlide=clone(this.currentSlide) as Slide;
      newSlide.avatarBackground=bg.background;
      updateCurrentSlide(newSlide);
    }
  }

  @Watch("avatarSelected")
  private onAvatarSelected(){
    if(this.currentSlide){
      const newSlide=clone(this.currentSlide) as Slide;
      newSlide.avatar=this.avatarSelected;
      updateCurrentSlide(newSlide);
    }
  }

  private onSlideLayoutUpdated(value){
    // update slides
    if(this.currentSlide){
      const newSlide=clone(this.currentSlide) as Slide;
      newSlide.layout=value;
      updateCurrentSlide(newSlide);
    }
  }
  public async mounted() {
    this.streamLoading = true;
    // setup favorite avatar
    if(store.getters.getFavAvatar){
      store.dispatch(SELECT_AI_AVATAR,store.getters.getFavAvatar)
    }
    // setup Favorite background
    if(store.getters.getFavAvatarBackground){
      store.dispatch(SELECT_AI_BACKGROUND,store.getters.getFavAvatarBackground)
    }
    // setup voice
    if(store.getters.getFavAvatarVoice){
      store.dispatch(SELECT_AI_VOICE,store.getters.getFavAvatarVoice)
    }
    // setup lang
    if(store.getters.getFavAvatarLang){
      store.dispatch(SELECT_AI_LANG,store.getters.getFavAvatarLang)
    }
  }

  private async initWeetEditing() {
    if (store.getters.getEditingWeetID === '') {
      await initWeetEditing()
    }
  }


  private async uploadAPdf(file: File) {
    store.dispatch(OPEN_AVATAR_EDITING_CONTENT, true)
    await store.dispatch(DOCUMENT_TO_LLM, {file, ext: ".pdf"})
    if(this.slides.length>=20){
      alertMessageCircle(this.$t('aiAvatar.tooMuchSlide').toString(),this.$t('aiAvatar.tooMuchSlideTitle').toString())
    }
  }



  private getAvatarContentEditingComponent(){
   return this.$refs['aiAvatarContentEdition'] as AIAvatarContentEditionPanel
  }
  private stopPreviewVoice(){
       this.getAvatarContentEditingComponent()?.stopPreviewVoice();
  }



  public async launchAvatarGeneration() {
    this.stopPreviewVoice();
    if (this.isAvatarGenerating) { //if not step loading
      return;
    }
    if (this.textToGenerate) {
      if(this.textToGenerate.length>this.maxlengthOfCharacter){
        if(this.isFreemium) {
          try {
            await confirmation(this.$t('aiAvatar.freemiumCharacterLimitation').toString(),
                this.$t('aiAvatar.freemiumCharacterLimitationTitle').toString(),
                this.$t('generic.upgrade').toString())
            this.$router.push({name: 'MyWorkspacePayment'});
          } catch (e) {
            // nothing to do when cancel
          }
        }else{
          alertMessageCircle(this.$t('aiAvatar.characterLimitation').toString(),
              this.$t('aiAvatar.freemiumCharacterLimitationTitle').toString(),)
        }
        return;
      }
      try {
        // this.loadingVoice = true;
        await this.initWeetEditing(); // obtain a weet ID
        let mediaAvatar = new Media();
        const audioMetadata = await this.getAvatarContentEditingComponent().getAudioInformationForText();
        const seconds = audioMetadata.seconds;
        const audioBlob = audioMetadata.blob;


        if (!this.currentSlide || this.slideLayout === ScriptSlideLayout.AVATAR_ONLY) { // Avatar Only
          // if no slide force layout
          this.slideLayout = ScriptSlideLayout.AVATAR_ONLY;
          await store.dispatch(CLEAR_STICKER_MEDIAID);
          await store.dispatch(GENERATE_MAIN_MEDIAID);
          // create a media
          mediaAvatar.mediaID = store.getters.getMainMediaId;
          mediaAvatar.type = SourceVideoType.AI_AVATAR;
        } else {
          // else create 2 mediaID
          await store.dispatch(CLEAR_STICKER_MEDIAID);
          await store.dispatch(CLEAR_MAIN_MEDIAID);
          await store.dispatch(GENERATE_MAIN_MEDIAID)
          await store.dispatch(GENERATE_STICKERS_MEDIAID)

          mediaAvatar.mediaID = store.getters.getStickersMediaId;
          mediaAvatar.type = SourceVideoType.AI_AVATAR;
        }

        let height = 720;
        if (this.slideLayout !== ScriptSlideLayout.AVATAR_ONLY) {
          height = 480;
        }
        // Launch avatar generation
        if (this.slideLayout != ScriptSlideLayout.VOICE_ONLY) {
          var params = new AiavatarGenerationModel(this.textToGenerate,
              this.avatarSelected, this.voiceSelected, this.langSelected, this.styleSelected,
              this.backgroundSelected?.url||LIST_OF_BACKGROUND[0].url, this.versionAvatar);
          params.finalOutputHeight = height;
          mediaAvatar = await store.dispatch(AIAVATAR_GENERATE, {
            media: mediaAvatar,
            audioBlob: audioBlob,
            aiavatarParams: params
          });
          console.log("RefreshedMedia: ", mediaAvatar)
          await store.dispatch(TIMELINE_UNSAVED);
          await store.dispatch(REFRESH_MEDIA, mediaAvatar);
        } else {
          // generate media Audio only
          const chunk = new ChunkMedia();
          chunk.blob = audioBlob;
          chunk.type = SourceVideoType.UPLOAD;
          chunk.mediaID = mediaAvatar.mediaID;
          chunk.partNumber = 1;
          chunk.weetID = store.getters.getEditingWeetID;
          chunk.lastPart = true;
          chunk.transcoding = true;

          // start generating (allow to create an event MEDIA_PLAY)
          store.dispatch(AVATAR_GENERATING, true);
          store.dispatch(RECORD_STICKER_VIDEO_MUTE, true)
          store.dispatch(RECORD_STICKER_POSITION, StickerPosition.NONE)
          await delay(200)
          store.dispatch(CREATE_CHUNK_MEDIA, chunk);
          // waiting mediaChunk starting
          await delay(500)
          // reset configuration after voice only avatar created
          store.dispatch(RECORD_STICKER_VIDEO_MUTE, false)
          store.dispatch(RECORD_STICKER_POSITION, StickerPosition.BOTTOM_LEFT)
          store.dispatch(AVATAR_GENERATING, false);
        }

        // compute duration of voice over
        const metadMediaMetadata = {status: "WAITING", duration: seconds};
        await store.dispatch(UPDATE_METADATA, metadMediaMetadata);


        // GEENRATE SLIDE
        if (this.currentSlide && this.slideLayout !== ScriptSlideLayout.AVATAR_ONLY) {
          let mediaSlide = new Media()
          mediaSlide.type = SourceVideoType.VIRTUAL_SCREENCAST
          mediaSlide.mediaID = store.getters.getMainMediaId
          mediaSlide = await store.dispatch(VIDEO_2_SLIDE_GENERATE, {
            mediaSlide,
            b64: this.currentSlide.imageB64,
            seconds
          })

          await store.dispatch(TIMELINE_UNSAVED);
          await delay(2000);
          await store.dispatch(REFRESH_MEDIA, mediaSlide);
        }

        // clear text
        await store.dispatch(AVATAR_EDITING_TEXT, "");


        if (this.currentSlide) {
          if (this.currentSlide.order < this.slides.length) {
            store.dispatch(SELECT_SLIDE, this.currentSlide.order);
          } else {
            store.dispatch(RESET_SLIDES_2_VIDEO)
          }
        }
        // send time to speach
        const dataEvent = new SpeachEventAvatarDuration(store.getters.getEditingWeetID, seconds)
        window.parent.postMessage(new SpeachEvent(SpeachEventName.AIAVATAR_DURATION, dataEvent), "*")
        // this.loadingVoice = false;

        await store.dispatch(AVATAR_GENERATING, false)
        await store.dispatch(TIMELINE_UNSAVED);
        await store.dispatch(SAVE_TIMELINE_IF_NEED);

        this.$emit('close');
      } catch (e) {
        console.error(e)
        // this.loadingVoice = false;
        store.dispatch(AVATAR_GENERATING, false)
        alertErrorWithConfirmation(this.$t('aiAvatar.content.error').toString()).then(() => {
          this.$emit('close');
        })
      }
    }
  }


  public async onCancelProcess(){
    this.cancelProcess=true;
    this.showModalWaiting=false;
    this.totalSlideToGenerate=0;
    this.nbSlideGenerated=0;
  }

  public finishProcess(){
    this.showModalWaiting=false;
    this.cancelProcess=false;
    this.showModalWaiting=false;
    this.totalSlideToGenerate=0;
    this.nbSlideGenerated=0;
  }
  public async generateAllSlide() {
    await store.dispatch(SELECT_SLIDE, 0)
    this.totalSlideToGenerate=this.slides.length
    this.showModalWaiting=true;
    this.cancelProcess=false;
    for (const slide of this.slides) {
      this.nbSlideGenerated++;
      await delay(10)
      if(!this.cancelProcess) {
        await this.launchAvatarGeneration()
        await store.dispatch(SELECT_SLIDE, slide.order);
      }
    }
  }

  public async generatethisSlide() {
    this.showModalWaiting=true;
    this.cancelProcess=false;
    this.nbSlideGenerated++;
    this.totalSlideToGenerate=1
    await this.launchAvatarGeneration()
  }

  beforeDestroy() {
    this.stopPreviewVoice();
  }
}
</script>

<style lang="scss">

// overload componennt
.uploadSlidePanel {
  .upload .upload-draggable {
    border-radius: 8px;
  }
}

</style>

<style lang="scss" scoped>
@import "@/scss/shadows.scss";

.AiEditorContainer {
  display: flex;
  width: 100%;
  animation-duration: 1s;
  border-radius: 8px;

  .buttonSelectionBlock{
    position: absolute;
    bottom:0px;
    left: -40px
  }
  .aiAvatarSelection {
    width: 100%;
    min-height: 314px;
    background: var(--light);
    border-radius: 8px;



    .avatarParameter {
      width: 232px;
      text-align: right;
      position: absolute;
      left: -300px;
      top: 48px;
      padding: 16px;
      background: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(12px);
      border-radius: 16px;

      .paramsSelect {
        margin-bottom: 24px;
        width: 200px;
      }

      .buttonNextStep {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }

      .labelSelect {
        position: relative;
        text-align: left;
        font-size: 14px;
        color: var(--dark);
        justify-content: space-between;
        align-items: flex-end;
        display: flex;
      }
    }

    .avatarBackground {
      position: absolute;
      width: 100%;
      left: 0px;
      height: 100%;
      background-size: cover;
      border-radius: 8px;

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 100%;
        border-radius: 8px;
      }
    }

    .avatarPreview {
      position: absolute;
      border: solid 1px var(--light1);
      border-radius: 12px;
      width: 100%;
      height: 100%;
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: 0px center;

      &.harry_business {
        background-image: url(@/assets/AIAvatar/HARRY-BUSINESS.png);
      }

      &.harry_casual {
        background-image: url(@/assets/AIAvatar/HARRY-CASUAL.png);
      }

      &.harry_youthful {
        background-image: url(@/assets/AIAvatar/HARRY-YOUTHFUL.png);
      }

      &.jeff_business {
        background-image: url(@/assets/AIAvatar/JEFF-BUSINESS.png);
      }

      &.jeff_formal {
        background-image: url(@/assets/AIAvatar/JEFF-FORMAL.png);
      }

      &.lori_casual {
        background-image: url(@/assets/AIAvatar/LORI-CASUAL.png);
      }

      &.lori_formal {
        background-image: url(@/assets/AIAvatar/LORI-FORMAL.png);
      }

      &.lori_graceful {
        background-image: url(@/assets/AIAvatar/LORI-GRACEFUL.png);
      }

      &.max_business {
        background-image: url(@/assets/AIAvatar/MAX-BUSINESS.png);
      }

      &.max_casual {
        background-image: url(@/assets/AIAvatar/MAX-CASUAL.png);
      }

      &.max_formal {
        background-image: url(@/assets/AIAvatar/MAX-FORMAL.png);
      }

      &.meg_business {
        background-image: url(@/assets/AIAvatar/MEG-BUSSINESS.png);
      }

      &.meg_casual {
        background-image: url(@/assets/AIAvatar/MEG-CASUAL.png);
      }

      &.meg_formal {
        background-image: url(@/assets/AIAvatar/MEG-FORMAL.png);
      }

      &.lisa_graceful-standing {
        background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_STANDING.png);
      }

      &.lisa_casual-sitting {
        background-image: url(@/assets/AIAvatar/LISA_CASUAL_SITTING.png);
      }

      &.lisa_graceful-sitting {
        background-image: url(@/assets/AIAvatar/LISA_GRACEFUL_SITTING.png);
      }

      &.lisa_technical-standing {
        background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_STANDING.png);
      }

      &.lisa_technical-sitting {
        background-image: url(@/assets/AIAvatar/LISA_TECHNICAL_SITTING.png);
      }

      // V2
      &.abigail_static {
        background-image: url(@/assets/AIAvatar/ABIGAIL.png);
      }

      &.arthur_static {
        background-image: url(@/assets/AIAvatar/ARTHUR.png);
      }

      &.darnel_static {
        background-image: url(@/assets/AIAvatar/DARNEL.png);
      }

      &.hannah_static {
        background-image: url(@/assets/AIAvatar/HANNAH.png);
      }

      &.marc_static {
        background-image: url(@/assets/AIAvatar/MARC.png);
      }

      &.isabella_static {
        background-image: url(@/assets/AIAvatar/ISABELLA.png);
      }

      &.jen_static {
        background-image: url(@/assets/AIAvatar/JEN.png);
      }

      &.marie_static {
        background-image: url(@/assets/AIAvatar/MARIE.png);
      }

      &.martha_static {
        background-image: url(@/assets/AIAvatar/MARTHA.png);
      }

      &.michelle_static {
        background-image: url(@/assets/AIAvatar/MICHELLE.png);
      }

      &.mike_static {
        background-image: url(@/assets/AIAvatar/MIKE.png);
      }
    }

    .bottomLeftAvatar {
      width: 100%;
      height: 100%;

      .uploadSlidePanel {
        width: 100%;
        height: 100%;

        .uploadHoverPanel {
          width: 100%;
          height: 100%;

          .uploadIcon {
            transition: all 0.2s ease-in-out;
            width: 60%;
            margin-left: auto;
            margin-top: 100px;
          }

          .uploadLabelTitle, .uploadLabelSubTitle {
            transition: all 0.2s ease-in-out;
            width: 60%;
            margin-top: 16px;
            margin-left: auto;
            font-size: 16px;
            color: var(--light2)
          }

          .uploadLabelSubTitle {
            transition: all 0.2s ease-in-out;
            margin-top: 8px;
            font-size: 12px;
          }

          &.centerLayout{
            .uploadIcon{
              width: 100%;
            }
            .uploadLabelTitle, .uploadLabelSubTitle {
              width: 100%;
            }
          }
        }

        .deletePDFLayout{
          position:absolute;
          width: 100%;
          height: 100%;
          background: rgba(10, 8, 47, 0.8);
          top:0;
          left:0;
          border-radius: 12px;
          display:flex;
          opacity: 0;
          align-items: center;
          justify-content: center;
          color: white;
          flex-direction: row;
          cursor: pointer;
          padding-left: 200px;
          transition: opacity 0.2s ease-in-out;
          &:hover{
            opacity: 1;
          }
          &.centerLayout{
            padding-left: 0px;
          }
        }
      }
    }

    .stickerAvatar {
      position: absolute;
      width: 180px;
      height: 180px;
      left: 16px;
      bottom: 16px;
      overflow: hidden;
      animation-duration: 0.2s;
      border-radius: 100% 100% 100% 12px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);

      .avatarPreview {
        border: 0px;
        background-size: 180%;
        background-position: 56% center;
      }
    }
  }

  .avatarLayoutSelection {
    position: absolute;
    opacity: 0.7;
    top: 16px;
    width: 100%;
    transition: opacity 0.2s ease-in-out;

    &:hover {
      opacity: 1;
    }
  }

  .backgroundSelector, .avatarSelector {
    background: white;
    border-radius: 8px;
    padding: 2px 8px;
    margin-top: 4px;
    border: solid 1px var(--light1);
    min-width: 200px;
    font-size: 12px;
    cursor: pointer;
    color: var(--dark);

    .backgroundSelectorContent, .avatarSelectorContent {
      text-align: left;
      display: flex;
      justify-content: space-between;
      align-items: center;
      text-transform: capitalize;
    }
  }

  .backgroundSelection, .avatarSelection {
    display: flex;
    align-items: center;

    .backgroundSelectionImage, .avatarSelectionImage {
      border: 1px solid var(--light1);
      display: block;
      width: 32px;
      height: 32px;
      background: var(--light);
      background-position: center center;
      margin-right: 16px;
      border-radius: 4px;
      background-size: cover;
    }

    .backgroundSelectionLabel, .avatarSelectionLabel {
      font-size: 14px;
      flex: 1;
      color: var(--dark);
    }

    .backgroundSelected, .avatarSelectionSelected {
      margin-right: 0px;
    }
  }

  .panelWaiting{
    background: white;
    width: 534px;
    min-height: 100px;
    padding-top: 32px ;
    padding-bottom: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    margin: auto;
    .centerPanelWaiting {
      width: calc(100% - 128px);
      .buttonCancel, .buttonOk{
        float: right;
      }
      .waitingLabel {
        text-align: left;
        font-weight: 900;
        font-size: 20px;
        margin-bottom: 16px;
      }

      .progressWaiting {
        width: 100%;
        .labelWaiting{
          font-weight: 700;
          font-size: 14px;
        }
      }

      .successFeedback{
        display: flex;
        .iconCheck{
          margin-right: 9px;
          display: flex;
          align-items: center;
        }
        .labelSuccess{
          flex:1;
          justify-content: flex-start;
          align-items: center;
        }
      }
    }
  }

  .aiAvatarContentEdition {
    position: absolute;
    min-height: 536px;
    min-width: 324px;
    max-width: 324px;
    right: -400px;
    top: -80px;
    padding: 16px;
    background: var(--white);
    border-radius: 8px;

  }

  .loadingPDFPanel {
    position: absolute;
    background: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 0;
    left: 0;
    padding-left: 64px;
    padding-right: 64px;
    width: 100%;
    height: 100%;
    .progressBar{
      width: 100%;
    }
    .labelLoadingPDFPanel{
      margin-top: 112px;
      font-size:16px;
      margin-bottom: 16px;
    }
  }
}

</style>
