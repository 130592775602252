import {
  AIAVATAR_GENERATE,
  AVATAR_EDITING_TEXT,
  AVATAR_GENERATING,
  OPEN_AVATAR_EDITING_CONTENT,
  SELECT_AI_AVATAR,
  SELECT_AI_BACKGROUND,
  SELECT_AI_LANG,
  SELECT_AI_VOICE,
  VIDEO_2_SLIDE_GENERATE
} from "@/store/AIAvatar/AIAVatarAction";
import {CREATE_MEDIA, MEDIA_UPDATE_MEDIAID} from "@/store/media/mediaAction";
import {Media} from "@/store/media/mediaModel";
import {
  AiavatarGenerationModel,
  Avatar,
  AvatarBackground, AvatarVoiceAvatar,
} from "@/store/AIAvatar/aiavatarGenerationModel";
import axios from "axios";
import {MediaIDUpdateParams, TIMELINE_UPDATE_MEDIAID} from "@/store/timeLine/timeLineAction";
import {UPDATE_MEDIA_ID_RECORDING_STATE} from "@/store/recordingState/recordStateAction";
import {
  ATTACHED_MEDIA_TO_WEET,
  ATTACHED_TIMELINE_TO_WEET,
  AttachedMediaParams,
  AttachedTimeLineParams
} from "@/store/myWeet/myWeetAction";
import {log} from "@/utils/log";
import store from "@/store";
import {AIAVATAR_API, SLIDE2VIDEO_API} from "@/store/media/mediaModule";
import {
  SAVE_AVATAR,
  SAVE_AVATAR_BACKGROUND,
  SAVE_AVATAR_LANG,
  SAVE_AVATAR_VOICE
} from "@/store/persistedInformation/persistAction";


export default {
  state: {
    openAvatarEditingContent:false,
    aiAvatarTextContent:"",
    avatarGenerating:false,
    aiAvatarSelected:Avatar.MARIE,
    aiAvatarVoiceSelected: AvatarVoiceAvatar.EN_US_AMANDA,
    aiAvatarLangSelected: null,
    aiAvatarBackgroundSelected: AvatarBackground.WHITE,
  },
  mutations: {
    [OPEN_AVATAR_EDITING_CONTENT]:(state:any,value:boolean)=>{
      state.openAvatarEditingContent=value;
    },
    [AVATAR_EDITING_TEXT]:(state:any,text:string)=>{
      state.aiAvatarTextContent=text;
    },
    [AVATAR_GENERATING]:(state:any,value:boolean)=>{
      state.avatarGenerating=value;
    },
    [SELECT_AI_AVATAR]:(state:any,value:Avatar)=>{
      state.aiAvatarSelected=value;
    },
    [SELECT_AI_VOICE]:(state:any,value:string)=>{
      state.aiAvatarVoiceSelected=value;
    },
    [SELECT_AI_LANG]:(state:any,value:{label:string,value:string})=>{
      state.aiAvatarLangSelected=value;
    },
    [SELECT_AI_BACKGROUND]:(state:any,value:AvatarBackground)=>{
      state.aiAvatarBackgroundSelected=value;
    },
  },
  getters: {
    getAvatarSelected:(state:any):Avatar =>{
      return state.aiAvatarSelected;
    },
    getAvatarVoiceSelected:(state:any):string =>{
      return state.aiAvatarVoiceSelected;
    },
    getAvatarLangSelected:(state:any): { labe:string,value:string } =>{
      return state.aiAvatarLangSelected;
    },
    getAvatarBabckgroundSelected:(state:any):AvatarBackground =>{
      return state.aiAvatarBackgroundSelected;
    },
    isOpenAvatarEditionContent: (state: any): boolean => {
      return state.openAvatarEditingContent;
    },
    getAiAvatarTextContent: (state: any): string => {
      return state.aiAvatarTextContent;
    },
    isAvatarGenerating:(state:any):string=>{
      return state.avatarGenerating;
    }
  },
  actions: {
    [OPEN_AVATAR_EDITING_CONTENT](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: boolean
    ) {
      commit(OPEN_AVATAR_EDITING_CONTENT, value);
    },
    [AVATAR_EDITING_TEXT](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        text: string
    ) {
      commit(AVATAR_EDITING_TEXT, text);
    },
    [AVATAR_GENERATING](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: boolean
    ) {
      commit(AVATAR_GENERATING, value);
    },
    [SELECT_AI_AVATAR](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: Avatar
    ) {
      commit(SELECT_AI_AVATAR, value);
      dispatch(SAVE_AVATAR,value)
    },
    [SELECT_AI_VOICE](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: string
    ) {
      commit(SELECT_AI_VOICE, value);
      dispatch(SAVE_AVATAR_VOICE,value)
    },
    [SELECT_AI_LANG](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: { label:string,value:string }
    ) {
      commit(SELECT_AI_LANG, value);
      dispatch(SAVE_AVATAR_LANG,value)
    },
    [SELECT_AI_BACKGROUND](
        {commit, dispatch, getters}: { commit: any, dispatch: any, getters: any },
        value: AvatarBackground
    ) {
      commit(SELECT_AI_BACKGROUND, value);
      dispatch(SAVE_AVATAR_BACKGROUND,value)
    },
    async [AIAVATAR_GENERATE]({
                                commit,
                                dispatch,
                                state,
                                getters
                              }: { commit: any, dispatch: any, state: any, getters: any },
                              {media,audioBlob, aiavatarParams}: { media: Media,audioBlob:Blob|null, aiavatarParams: AiavatarGenerationModel }) {

      if (getters.getEditingWeetID === '') {
        // so there is a huge problem here
        throw Error('Impossible to create aiavatar if there is no weet ID');
      }
      commit(CREATE_MEDIA, media);
      commit(AVATAR_GENERATING, true)
      const formData = new FormData();
      if(audioBlob) {
        formData.append('audioBlob', audioBlob);
      }
      formData.append('text',aiavatarParams.text);
      formData.append('voice',aiavatarParams.voice);
      formData.append('lang',aiavatarParams.lang+"");
      formData.append('avatar',aiavatarParams.avatar);
      formData.append('style',aiavatarParams.style);
      formData.append('backgroundUrl',aiavatarParams.backgroundUrl)
      formData.append('version',aiavatarParams.version+"")
      formData.append('finalOutputHeight',aiavatarParams.finalOutputHeight+"")
      const response = await axios(
          {
            url: AIAVATAR_API,
            method: 'POST',
            data: formData,
            headers: {
              'Content-Type': 'multipart/form-data',
              'Content-Disposition': 'form-data; name=media',
            },
          }).then(async (resp) => {

        const refreshMedia = resp.data;
        refreshMedia.saved = true;
        // now we update the mediaID
        const params = new MediaIDUpdateParams(media.mediaID, refreshMedia);

        await commit(MEDIA_UPDATE_MEDIAID, params);
        await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);

        await dispatch(TIMELINE_UPDATE_MEDIAID, params);


        const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
        await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);

        log.debug('Is All Media Saved = ' + getters.isAllMediaSavedAndValidate + ' and Validate');
        if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
          // after the media is really update we start the synchronisation of the timeline
          const attachedTimeLineParams = new AttachedTimeLineParams(
              getters.getEditingWeetID,
              getters.getTimeEvent,
              getters.getOffsetTimeEventUpdate
          );
          store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
        }
        return refreshMedia;
      }).catch((err) => {
        commit(AVATAR_GENERATING, false)
        throw err;
      });
      return response;
    },
    async [VIDEO_2_SLIDE_GENERATE]({
                                     commit,
                                     dispatch,
                                     state,
                                     getters
                                   }: { commit: any, dispatch: any, state: any, getters: any },
                                   {mediaSlide, b64, seconds}: { mediaSlide: Media,b64:string, seconds: number }) {

      if (getters.getEditingWeetID === '') {
        // so there is a huge problem here
        throw Error('Impossible to create aiavatar if there is no weet ID');
      }
      commit(CREATE_MEDIA, mediaSlide);
      const response = await axios(
          {
            url: SLIDE2VIDEO_API,
            method: 'POST',
            data: {b64,seconds}
          }).then(async (resp) => {
        const refreshMedia = resp.data;
        refreshMedia.saved = true;
        // now we update the mediaID
        const params = new MediaIDUpdateParams(mediaSlide.mediaID, refreshMedia);
        await commit(MEDIA_UPDATE_MEDIAID, params);
        await dispatch(UPDATE_MEDIA_ID_RECORDING_STATE, params);

        await dispatch(TIMELINE_UPDATE_MEDIAID, params);


        const attachedParams = new AttachedMediaParams(getters.getEditingWeetID, params.media.mediaID);
        await dispatch(ATTACHED_MEDIA_TO_WEET, attachedParams);
        log.debug('Is All Media Saved = ' + getters.isAllMediaSavedAndValidate + ' and Validate');
        if (getters.isAllMediaSavedAndValidate && getters.timelineNeedToBeSaved) {
          // after the media is really update we start the synchronisation of the timeline
          const attachedTimeLineParams = new AttachedTimeLineParams(
              getters.getEditingWeetID,
              getters.getTimeEvent,
              getters.getOffsetTimeEventUpdate
          );
          store.dispatch(ATTACHED_TIMELINE_TO_WEET, attachedTimeLineParams);
        }
        return refreshMedia;
      }).catch((err) => {
        throw err;
      });
      return response;
    },
  },
};
