import {ChunkMedia, Media} from '@/store/media/mediaModel';

export const MEDIA_UPLOAD_START = 'MEDIA_UPLOAD_START';
export const MEDIA_CHUNK_UPLOAD_START = 'MEDIA_CHUNK_UPLOAD_START';
export const MEDIA_UPLOAD_PROGRESS = 'MEDIA_UPLOAD_PROGRESS';
export const MEDIA_UPLOAD_END = 'MEDIA_UPLOAD_END';
export const CONNECTION_UPLOAD_PROBLEM = 'CONNECTION_UPLOAD_PROBLEM';
export const UPLOAD_FILE_DESCRIPTION = 'UPLOAD_FILE_DESCRIPTION';

export class MediaUploadParams {
    public media: Media;

    constructor(media: Media) {
        this.media = media;
    }
}

export class MediaUploadChunkParams {
    public chunkMedia: ChunkMedia;

    constructor(chunkMedia: ChunkMedia) {
        this.chunkMedia = chunkMedia;
    }
}

export interface MediaMetadata {
    duration: number;
    status: string;
}
