<template>
  <div>
    <div class="timeLineBigContainer">
      <transition name="zoom">
        <div class="emptySectionState" :key="'noSection'" v-show="segments.length===0 && !isRecording">{{
          $t('createWeetComponent.timeline.emptySection')
          }}
        </div>
      </transition>

      <Draggable v-if="segments.length>0 && rearrange" group="segments" class="dragableContainer"
                 v-show="advancedAivailable"
                 @end="updateOrderTimeLine">

        <div v-for="(segment,index) in segments" class="itemBigTimeElement" :key="keyForSegment(segment)">
          <TimeLineElementBig :index="index" :segment="segment"
          :buttonLabel="buttonLabel" @action="actionButton"/>

        </div>

      </Draggable>
      <div v-else  class="dragableContainer">
        <div v-for="(segment,index) in segments" class="itemBigTimeElement" :key="keyForSegment(segment)">
          <TimeLineElementBig :index="index" :segment="segment"
                              :delete-available="rearrange" :tooltip-label="$t('createWeetComponent.timeline.click')"
                              :buttonLabel="buttonLabel" @action="actionButton"/>

        </div>
      </div>
      <!-- Progress  bar -->
      <div class="progress" :style="'max-width:100%;width:'+progressBar+'%'"></div>
      <div class="disabledComponent" v-if="isRecording">

      </div>

    </div>

  </div>
</template>

<script lang="ts">
import {Component, Emit, Prop, Vue, Watch} from 'vue-property-decorator';
import store from '@/store';
import {Segment, TimeEvent} from '@/store/timeLine/timeEvent';
import {TimeEventType} from '@/enum/TimeEventType';
import TimeLineElement from '@/components/recorder/timeLine/TimeLineElement.vue';
import TimeLineElementBig from '@/components/recorder/timeLine/TimeLineElementBig.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WButton from '@/components/wrapper/w-button.vue';
import prettyMilliseconds from 'pretty-ms';
import Draggable from 'vuedraggable';
import {MOVE_SECTION_EVENT} from '@/store/timeLine/timeLineAction';
import {getDurationOfTimeLine} from '@/utils/timeLineUtils';
import WToolTip from '@/components/wrapper/w-toolTip.vue';

@Component({
  components: {
    WToolTip,
    Draggable, WButton, WIcon, TimeLineElementBig, TimeLineElement
  },
})
export default class TimeLine extends Vue {

  @Prop({default: true})
  private advancedAivailable!: boolean;

  @Prop({default:true})
  private rearrange!:boolean;

  @Prop({default:""})
  private buttonLabel!:string;

  // select automatically the section if only on e section
  @Prop({default:false})
  private autoselectIfAlone!:boolean;



  get timeMax(): number {
    // convert second in millisecond
    return store.getters.getWeetMaxTime * 1000;
  }

  get humanTimeMax(): string {
    return prettyMilliseconds(this.timeMax, {colonNotation: true, secondsDecimalDigits: 0});
  }

  get isRecording(): boolean {
    return store.getters.isPauseAivailable;
  }

  get getTimeEvents(): TimeEvent[] {
    return store.getters.getTimeEvent;
  }

  get humanTimerRecord(): string {
    return prettyMilliseconds(store.getters.getTimerRecord, {secondsDecimalDigits: 0, colonNotation: true});
  }


  get segments(): Segment[] {
    const segments: Segment[] = [];

    let startEvent;
    for (const time of this.getTimeEvents) {
      if (time.type === TimeEventType.MEDIA_PLAY && !startEvent) {
        startEvent = time;
      } else if (time.type === TimeEventType.MEDIA_PAUSE ||
          time.type === TimeEventType.MEDIA_PLAY) {
        const segment = new Segment(startEvent, time);
        // the key is the media at a time
        segment.id = segment.start.time + '_' + segment.start.mainMedia.mediaID;
        segments.push(segment);
        startEvent = time;

        if (time.type === TimeEventType.MEDIA_PAUSE) {
          break;
        }
      }
    }
    return segments;
  }

  private keyForSegment(segment:Segment){
    let key= segment.id;
    const mainMedia=store.getters.getMediasForMediaId(segment.start.mainMedia.mediaID);
    if(mainMedia) {
      key += mainMedia.status+mainMedia.imageBlobURL+mainMedia.progress;
    }
    const secondMedia=store.getters.getMediasForMediaId(segment.start.secondMedia.mediaID);
    if(secondMedia){
      key+=secondMedia.status+secondMedia.imageBlobURL+secondMedia.progress;
    }
    return key;
  }

  get getTimerRecord(): number {
    return store.getters.getTimerRecord;
  }

  get progressBar(): number {
    return this.getTimerRecord / (store.getters.getWeetMaxTime * 1000) * 100;
  }

  mounted(){
    if(this.autoselectIfAlone && this.segments.length===1){
      this.actionButton(this.segments[0]);
    }
  }

  @Emit('action')
  private actionButton(segment:Segment){
    return
  }
  private updateOrderTimeLine(event) {
    const oldIndex = event.oldIndex;
    const newIndex = event.newIndex;
    let startSection;
    let moveTo;
    if (oldIndex > newIndex) {
      startSection = this.segments[oldIndex].start.time;
      moveTo = this.segments[newIndex].start.time;
    }
    if (newIndex > oldIndex) {
      startSection = this.segments[oldIndex].start.time;
      if (newIndex + 1 >= this.segments.length) {
        moveTo = getDurationOfTimeLine(this.getTimeEvents);
      } else {
        moveTo = this.segments[newIndex + 1].start.time;
      }
    }

    if (startSection !== undefined && moveTo !== undefined) {
      // now we change the timeline
      store.dispatch(MOVE_SECTION_EVENT,
          {startSection: startSection, moveTo: moveTo});
    }

  }
}
</script>

<style scoped lang="scss">

.timeLineSegmentContainer {
  display: flex;
  width: 100%;
  max-width: 100%;

  .itemTimeElement {
    flex-grow: 1;
    flex-basis: 0px;
    display: flex;
    max-width: 100px;
    margin-right: 8px;
    align-content: flex-end;

    height: 16px;
    // Hack for tooltip appears:-(

    div {
      width: 100%;
    }
  }
}

.timeLineBigContainer {
  display: flex;
  position: relative;
  width: 100%;
  max-width: 100%;;
  background: var(--light);
  animation-duration: 0.2s;
  height: 72px;
  padding: 4px;
  border-radius: 0px 0px 8px 8px;

  .progress {
    width: 0;
    background: var(--primary);
    position: absolute;
    top: 0px;
    height: 4px;
    margin-top: -2px;
    border-radius: 4px;
  }

  .dragableContainer {
    display: flex;
    width: 100%;
    max-width: 100%;
    border-radius: 4px;

    .itemBigTimeElement {
      flex-grow: 1;
      flex-basis: 0;
      max-width: 117px;
      margin-right: 4px;
      align-content: flex-end;
      height: 66px;
    }
  }

  .disabledComponent {
    position: absolute;
    top: 2px;
    left: 0px;
    width: 100%;
    background: rgba(255, 255, 255, 0.5);
    height: 100%;
    cursor: not-allowed;
    border-radius: 8px;
  }

  .emptySectionState {
    position: absolute;
    top: 0;
    left: 0px;
    display: flex;
    min-width: 400px;
    font-size: 16px;
    color: var(--light2);
    // text-shadow: 0px 0px 5px var(--light1);
    width: 100%;
    font-weight: 600;
    letter-spacing: 0.3px;
    height: 100%;
    justify-content: center;
    align-items: center;
    padding-right: 32px;
    padding-left: 32px;

    &.right {
      justify-content: flex-end;
    }
  }
}

.openButtonContainer {
  font-size: 14px;
  text-align: center;
  font-weight: 900;
  color: var(--light2);
  letter-spacing: 0.3px;
  transition: all 0.5s linear;

}
</style>
