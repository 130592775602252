export const OPEN_AVATAR_EDITING_CONTENT='OPEN_AVATAR_EDITING_CONTENT'
export const AVATAR_EDITING_TEXT="AVATAR_EDITING_TEXT";
export const AVATAR_GENERATING="AVATAR_GENERATING"
export const AIAVATAR_GENERATE='AIAVATAR_GENERATE'
export const VIDEO_2_SLIDE_GENERATE='VIDEO_2_SLIDE_GENERATE'
export const SELECT_AI_AVATAR='SELECT_AI_AVATAR'
export const SELECT_AI_AVATAR_GENDER='SELECT_AI_AVATAR_GENDER'
export const SELECT_AI_VOICE='SELECT_AI_VOICE'
export const SELECT_AI_LANG='SELECT_AI_LANG'
export const SELECT_AI_BACKGROUND='SELECT_AI_BACKGROUND'
