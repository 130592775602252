import { render, staticRenderFns } from "./StickerVideoPlayer.vue?vue&type=template&id=5b136188&scoped=true"
import script from "./StickerVideoPlayer.vue?vue&type=script&lang=ts"
export * from "./StickerVideoPlayer.vue?vue&type=script&lang=ts"
import style0 from "./StickerVideoPlayer.vue?vue&type=style&index=0&id=5b136188&prod&lang=scss"
import style1 from "./StickerVideoPlayer.vue?vue&type=style&index=1&id=5b136188&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b136188",
  null
  
)

export default component.exports