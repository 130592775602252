<template>
  <div class="avatarBackgroundSelectionPanel">
    <div class="avatarBackgroundSelectionTitle">
      {{ $t('aiAvatar.background') }}
    </div>
    <perfect-scrollbar ref="thumbnailScroll">
      <div class="thumbnailGrid">
          <div class="backgroundSelection" @click="(isFreemium && !background.freemium)?null:onBackgroundSelected(background)" v-for="background in listOfBackground"
               :key="'background_'+background.background">
            <span class="backgroundThumbnail" :style="'background-image:url('+background.url+')'"
                  :class="{selected:background.background===backgroundSelected}">
              <div class="backgroundSelectedCheck" v-if="background.background===backgroundSelected">
                <w-icon icon="check-circle" color="primary" />
              </div>
              <div class="freemiumOverlay" v-if="isFreemium && !background.freemium" >
                <upgrade-plan-tag-button size="small"/>
              </div>
            </span>
            <span class="backgroundSelectionLabel">{{ background.label }}</span>
          </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import store from '@/store';
import {
  RECORD_MAIN_VIDEO_TYPE,
  RECORD_STICKER_FACE_TRACK,
  RECORD_STICKER_FILTER, RECORD_STICKER_FULLSCREEN,
  RECORD_STICKER_INTEGRATION, RECORD_STICKER_POSITION, RECORD_STICKER_VIDEO_MUTE, RECORD_STICKER_VIDEO_TYPE,
  RECORD_STICKER_VIRTUAL_BACKGROUND,
} from '@/store/recordingState/recordStateAction';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import {setActionEvent} from '@/utils/tracker';
import {ActionEvent} from '@/enum/TrackerEnum';
import WIcon from '@/components/wrapper/w-icon.vue';
import {getImageForVBCustom, listOfFilter, listOfVirtualBackground} from '@/utils/filterUtils';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {VirtualBackgroundEnum} from '@/enum/VirtualBackgroundEnum';
import {SETUP_FAVORITE_FACE_TRACK, SETUP_FAVORITE_FILTER} from '@/store/setup/setupAction';
import {getUserAvatar} from '@/utils/util';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WSwitch from '@/components/wrapper/w-switch.vue';
import WHelp from '@/components/wrapper/w-help.vue';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import CustomVBUploader from '@/components/recorder/sticker/buttons/CustomVBUploader.vue';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {Avatar, AvatarBackground, LIST_OF_AVATAR, LIST_OF_BACKGROUND} from "@/store/AIAvatar/aiavatarGenerationModel";
import {SELECT_AI_AVATAR, SELECT_AI_BACKGROUND} from "@/store/AIAvatar/AIAVatarAction";
import UpgradePlanTagButton from "@/components/upgradePlan/UpgradePlanTagButton.vue";
import {isFreemium} from "@/utils/workspaceUtil";


@Component({
  components: {
    UpgradePlanTagButton,
    CustomVBUploader, WHelp, WSwitch, CenterCenterPanel, WModal, WToolTip, WIcon, WButton, VideoSource},
  computed: {},
})
export default class AIAvatarSelectionPanel extends Vue {

  private listOfBackground = LIST_OF_BACKGROUND;

  get isFreemium(): boolean {
    return isFreemium()
  }

  get backgroundSelected(){
    return store.getters.getAvatarBabckgroundSelected
  }

  private onBackgroundSelected(background) {
    store.dispatch(SELECT_AI_BACKGROUND, background.background)
  }


}
</script>

<style lang="scss" scoped>

.avatarBackgroundSelectionPanel {
  background: var(--white);
  height: 530px;
  padding-bottom: 48px;

  .avatarBackgroundSelectionTitle {
    font-size: 20px;
    color: var(--black);
    font-weight: 900;
    padding-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ps {
    padding-left: 16px;
    padding-right: 16px;
    max-height: 100%;
  }
  .thumbnailGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    .avatarSelection{
      cursor: pointer;
    }
    .backgroundThumbnail {
      cursor: pointer;
      position: relative;
      display: block;
      aspect-ratio: 16/9;
      background: var(--light);
      border-radius: 8px;
      width: 100%;
      border: solid 2px var(--light);
      image-rendering: crisp-edges;
      background-size: cover;
      &:hover{
        border-color: var(--light1);
      }
      &.selected {
        border-color: var(--primary);
      }

      .avatarProfile{
        width: 100%;
        height: 100%;
        background-size: contain;
      }

      .freemiumOverlay{
        opacity: 1;
        background: rgba(10, 8, 47, 0.4);
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
      }
    }
    .backgroundSelectionLabel{
      display: block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 4px;
      margin-bottom: 8px;
    }

    .backgroundSelectedCheck {
      background: white;
      height: 24px;
      width: 24px;
      border-radius: 100%;
      top:0px;
      right: 0px;
      position: absolute;
    }

  }
}

</style>
