<template>
  <div>
    <transition name="fade" v-if="showSticker">
      <div class="sticker" :class="stickerStateClass"
           v-show="webcamAivailable && mustShow && !hideSticker "
           :style="'background-image:url('+stickerBackground+')'">
        <div class="stickerLayout">
          <VideoSource class="videoElement" :filter="true" :sticker="true" :type="sourceVideo.WEBCAM"
                       @mediaLoaded="onMediaLoaded"/>
        </div>
      </div>
    </transition>
    <Transition name="fade">
      <div class="hideStickerButton" @click="removeSticker"
           v-show="showSticker && webcamAivailable && mustShow && !hideSticker && !isRecording">
        <w-tool-tip :label="$t('stickers.button.close.tooltip').toString()">
          <w-icon icon="close-circle" class="iconClose" size="small"/>
        </w-tool-tip>
      </div>
    </Transition>
    <div class="buttonSticker" v-show="!readonly">
      <transition-group name="zoom">
        <StickerRemoveStickerButton data-test="removeStickerButton" :key="'button4'"
                                    v-show="mustShow && !isRecording && isVideoMute  && !isAvatarAI"
                                    class="button3"/>
        <StickerFilterButton data-test="recorderFilterButton" :key="'button3'"
                             v-show="mustShow && !isRecording && !isVideoMute  && !isAvatarAI"
                             class="button3" @open="onOpenFilter"
                             style="animation-duration: 0.3s;animation-delay: 0.4s;"/>
        <StickerVideoMuteButton color="light" :key="'button2'"
                                v-show="mustShow && !isRecording  && !isAvatarAI"
                                class="button2"
                                style="animation-duration: 0.3s;animation-delay: 0.2s;"/>
        <StickerMicMuteButton color="light" :key="'button1'"
                              v-show="mustShow && !isRecording  && !isAvatarAI"
                              class="button1"
                              style="animation-duration: 0.3s"/>
        <StickerSettingButton color="light" :key="'button0'"
                              v-show="mustShow && !isRecording && !isAvatarAI"
                              class="button0"
                              style="animation-duration: 0.3s"/>
      </transition-group>
    </div>

  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import StickerFilterButton from '@/components/recorder/sticker/buttons/StickerFilterButton.vue';
import StickerVideoMuteButton from '@/components/recorder/sticker/buttons/StickerVideoMuteButton.vue';
import StickerMicMuteButton from '@/components/recorder/sticker/buttons/StickerMicMuteButton.vue';
import StickerSettingButton from '@/components/recorder/sticker/buttons/StickerSettingButton.vue';
import {getUserAvatar} from '@/utils/util';
import StickerRemoveStickerButton from '@/components/recorder/sticker/buttons/StickerRemoveStickerButton.vue';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {RECORD_STICKER_POSITION, RECORD_STICKER_VIDEO_MUTE} from '@/store/recordingState/recordStateAction';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';


@Component({
  components: {
    WToolTip,
    WIcon,
    StickerRemoveStickerButton,
    StickerSettingButton,
    StickerMicMuteButton,
    StickerVideoMuteButton,
    StickerFilterButton,
    WButton, VideoSource,
  },
  computed: {},
})
export default class StickerLayoutSource extends Vue {
  @Prop({default: false})
  private readonly!: boolean;

  private mustShow: boolean = false;
  private isHover: boolean = false;

  private isTranscriptResult: boolean = false;
  private transcriptTimer: number = 0;

  get isVideoMute(): boolean {
    return store.getters.isStickerVideoMute;
  }

  get filter(): any {
    return FilterVideoType;
  }

  get sourceVideo(): any {
    return SourceVideoType;
  }

  get hideSticker() {
    return store.getters.getStickerPosition === StickerPosition.NONE;
  }

  get showSticker() {
    // no sticker if webcam Full
    if (store.getters.getMainRecordSource === SourceVideoType.WEBCAM || store.getters.getMainRecordSource === SourceVideoType.UPLOAD
    || store.getters.getMainRecordSource===SourceVideoType.NONE) {
      return false;
    }
    if (store.getters.getMainRecordSource === SourceVideoType.SCREENCAST
        && store.getters.getWebCamIntegration !== WebCamIntegrationTypeEnum.NONE) {
      return false;
    }
    if(store.getters.getMainRecordSource===SourceVideoType.AI_AVATAR){
      return false;
    }
    return true;
  }

  get webcamAivailable() {
    return store.getters.isWebcamAivalable;
  }

  get isRecording() {
    return !store.getters.isStartRecordingAivailable;
  }

  get stickerBackground(): string {
    return getUserAvatar(store.getters.userConnected, 512);
  }

  get stickerCurrentMediaID(): string {
    return store.getters.getStickersMediaId;
  }

  public mounted() {
    setTimeout(() => {
      this.mustShow = true;
    }, 1000);
  }

  get stickerStateClass(): string {
    const fullscreen = store.getters.isStickerFullscreen;
    const position = store.getters.getStickerPosition;
    if (fullscreen) {
      return 'fullScreen';
    } else {
      return position;
    }
  }

  get isAvatarAI():boolean{
    return store.getters.getMainRecordSource===SourceVideoType.AI_AVATAR;
  }

  public removeSticker() {
    if (!this.isVideoMute) {
      store.dispatch(RECORD_STICKER_VIDEO_MUTE);
    }
    store.dispatch(RECORD_STICKER_POSITION, StickerPosition.NONE);
  }

  public onOpenFilter(value) {
    this.$emit('openFilter', value);
  }

  public onMediaLoaded() {
    this.$emit('mediaLoaded');
  }

}
</script>
<style lang="scss" scoped>
@import '@/scss/shadows.scss';

//commun
.sticker {
  position: absolute;
  //border: 1px solid #FFFFFF;
  min-width: 154px;
  min-height: 154px;
  max-width: 154px;
  max-height: 154px;
  overflow: hidden;
  border-radius: 100% 100% 100% 16px;
  transition: all 0.2s;
  bottom: 16px;
  left: 56px;
  //fix issue in safari border-radius and overflow
  // -webkit-mask-image: -webkit-radial-gradient(white, black);
  background: white;
  background-size: contain;
  background-repeat: no-repeat;
  @extend .stickersShadow;


  .stickerLayout {
    min-height: 154px;
    transform: scale3d(-1, 1, 1);
    transition: all 0.5s;

    .videoElement {
      position: absolute;
      min-height: 110px;
      left: 0;
      width: 100%;
      height: 100%;
    }

  }
}

.hideStickerButton {
  position: absolute;
  bottom: 145px;
  left: 192px;
  cursor: pointer;
  color: white;
  transition: transform 0.2s ease;

  .iconClose {
    @extend .dropShadow1;
  }

  &:hover {
    transform: scale(1.02);
  }

}

.transcriptElement {
  bottom: 32px;
  margin-left: 84px;
  position: absolute;
}

.buttonSticker {
  margin-left: 0px;
  position: absolute;
  bottom: 0px;


  .button0 {
    position: absolute;
    bottom: 0px;

  }

  .button1 {
    position: absolute;
    bottom: 40px;

  }

  .button2 {
    position: absolute;
    bottom: 80px;
  }

  .button3 {
    position: absolute;
    bottom: 120px;
  }

}

.bulleTranscript {
  position: absolute;
  left: 160px;
  bottom: 200px;
  width: calc(100% - 320px);
  height: 30px;
  max-height: 250px;
  font-size: 14px;
  border-radius: 8px;
  color: white;
}

</style>
