<template>
  <div class="progress">
    <div class="titleProgress" v-if="!hasError">
      <w-icon class="progressIcon" :icon="waitingIcon" size="large"/>
      {{ $t('createWeetComponent.advancedEditing.progress.proccess') }}
    </div>
    <div v-else class="errorProgress">
      <w-icon icon="alert-outline" color="danger" size="large"/>
      {{ $t('createWeetComponent.advancedEditing.progress.error') }}
    </div>
    <b-progress class="progressBar" format="percent" type="is-primary" size="is-large" :value="updateAvancement"
                show-value/>


  </div>
</template>


<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import WIcon from '@/components/wrapper/w-icon.vue';
import {TimeEvent} from '@/store/timeLine/timeEvent';
import {Media} from '@/store/media/mediaModel';
import {getThumbnailURL, getUserAvatar} from '@/utils/util';
import UserAvatar from '@/components/user/UserAvatar.vue';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {Weet} from '@/store/weet/weetModel';
import {hasError, isWeetIsReady, progressWeetProceed} from '@/utils/weetUtil';
import {has} from "lodash";

@Component({
  methods: {has},
  components: {UserAvatar, WIcon}
})
export default class ProgressProcessPlayer extends Vue {

  @Prop()
  private weet!: Weet;
  private index = 0;

  get updateAvancement(): number {
    if(this.weet) {
      this.index++;
      return progressWeetProceed(this.weet);
    }else{
      return 0;
    }
  }

  get hasError(){
    if(this.weet) {
      return hasError(this.weet);
    }
    return false;
  }

  get waitingIcon() {
    const icons = ['clock-time-one', 'clock-time-two',
      'clock-time-three', 'clock-time-four', 'clock-time-five',
      'clock-time-six', 'clock-time-seven', 'clock-time-eight',
      'clock-time-nine', 'clock-time-ten', 'clock-time-eleven',
      'clock-time-twelve'];
    const value = this.index % icons.length;
    return icons[value];
  }


}
</script>


<style scoped lang="scss">
@import '@/scss/gridBreakPoint.scss';
@import '@/scss/shadows.scss';


.progress {
  width: 100%;
  height: 100%;
  border-radius: 0px;

  .titleProgress {
    font-size: 20px;
    font-weight: 900;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
    .progressIcon{
      margin-bottom: 16px;
    }
    .progressBar {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
  .errorProgress{
    color: white;
    font-size: 24px;
    font-weight: 900;
    margin-bottom: 32px;
  }
}

</style>
