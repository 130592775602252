<template>
  <div class="weetAdvancedEditingInteractionContainer">
    <div class="colContainer">
      <div class="colLeft">
        <perfect-scrollbar class="interactionListContainer" :options="{suppressScrollX:true}" ref="scrollPanel"
                           v-if="listOfAnnotation.length>0">

          <w-button class="buttonAddList" color="outlined" :expanded="true" size="small" icon="message-outline" @click="createInteraction">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.add') }}
          </w-button>
          <transition-group name="list-complete">
            <!-- list of annotations -->
            <AnnotationListElement class="list-complete-item" :weet="weet" :annotation="annot"
                                   v-for="annot in listOfAnnotation" style="transition: transform 1s"
                                   :key="annot.annotationID+'-annot'"
            @delete="deleteInteraction"/>
          </transition-group>
        </perfect-scrollbar>


        <div v-else class="interactionListContainer emptyState"> <!-- EMPTY STATE -->
          <div class="emptyStateTitle">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.emptyState.title') }}
          </div>
          <div class="emptyStateDescription">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.emptyState.description') }}
          </div>
          <w-button class="buttonAddList" color="outlined" :expanded="true" size="small" icon="message-outline"
          @click="createInteraction">
            {{ $t('createWeetComponent.advancedEditing.interactionMode.add') }}
          </w-button>
        </div>
      </div>
      <div class="colMiddle">
        <transition name="zoom">
          <perfect-scrollbar v-show="selectedAnnotation" v-if="selectedAnnotation && listOfAnnotation.length>0"
                             class="interactionListOptionContainer"
                             :options="{suppressScrollX:true}"
                             ref="scrollPanelOption">
            <div class="optionLineTitle">
              {{ $t('createWeetComponent.advancedEditing.interactionMode.option.title') }}
            </div>
            <!-- Start -->
            <div class="optionLine">
              <div class="labelOption">
                {{
                  $t('createWeetComponent.advancedEditing.interactionMode.option.start')
                }}

              </div>
              <div class="labelTimeInput">
                <transition name="fade">
                  <div class="timeHelper" @click="setStart(timePlayer/1000)" v-show="showStartHelper">
                    {{
                      $t('createWeetComponent.advancedEditing.interactionMode.option.setButton', { time: getHumanTime(timePlayer) })
                    }}
                  </div>
                </transition>
                <w-input class="timeInput" @focus="showStartHelper=true"
                         @blur="setStart" size="small"
                         :value="getHumanTime(selectedAnnotation.time,1)"
                         :validate-on-enter="true"/>
              </div>
              <div class="labelOption">
                {{
                  $t('createWeetComponent.advancedEditing.interactionMode.option.end')
                }}
              </div>
              <div class="labelTimeInput">
                <transition name="fade">
                  <div class="timeHelper" @click="setEnd(timePlayer/1000)" v-show="showDurationHelper && isDurationButtonAivalable">
                    {{
                      $t('createWeetComponent.advancedEditing.interactionMode.option.setButton', { time: getHumanTime(timePlayer) })
                    }}
                  </div>
                </transition>
                <w-input class="timeInput" @focus="showDurationHelper=true"
                         @blur="setEnd"
                         size="small"
                         :value="getHumanTime(selectedAnnotation.time+selectedAnnotation.duration,1)"
                         :validate-on-enter="true"/>
              </div>
            </div>
            <div class="optionLine"  :class="{marginHelper:showStartHelper||(showDurationHelper && isDurationButtonAivalable)}">
              <div class="labelOption">
                {{
                  $t('createWeetComponent.advancedEditing.interactionMode.option.duration')
                }}
              </div>
              <div class="labelTimeInput">
                <w-input class="timeInput" @focus="showDurationHelper=true"
                         @blur="setDuration"

                         size="small"
                         :value="getHumanTime(selectedAnnotation.duration,1)"
                         :validate-on-enter="true"/>
              </div>
            </div>
          </perfect-scrollbar>
        </transition>
      </div>
      <div class="colRight">
        <w-button icon="check" class="buttonSave" :loading="loading|| isAnnotationInteractionSaveInProgress" @click="back">
          {{ $t('createWeetComponent.advancedEditing.validate') }}
        </w-button>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {Component, Prop, Vue} from 'vue-property-decorator';
import {Weet} from '@/store/weet/weetModel';
import WButton from "@/components/wrapper/w-button.vue";
import store from "@/store";
import prettyMilliseconds from "pretty-ms";
import AnnotationListElement from "@/components/recorder/annotation/AnnotationListElement.vue";
import {Annotation, AnnotationPosition, AnnotationTheme, AnnotationType} from "@/store/annotation/annotationModel";
import {
  CREATE_ANNOTATION,
  DELETE_ANNOTATION,
  SELECT_ANNOTATION,
  UNSELECT_ANNOTATION,
  UPDATE_ANNOTATION
} from "@/store/annotation/annotationAction";
import WSwitch from "@/components/wrapper/w-switch.vue";
import WIcon from "@/components/wrapper/w-icon.vue";
import {confirmationDanger, inform, informError} from "@/utils/dialog";
import WModal from "@/components/wrapper/w-modal.vue";
import CenterCenter from "@/components/layout/CenterCenter.vue";
import IconsSelector from "@/components/icons/IconsSelector.vue";
import {exportNode2PngDataURL} from "@/utils/ImageUtil";
import CreateAnnotationButton from "@/components/recorder/button/CreateAnnotationButton.vue";
import WInput from "@/components/wrapper/w-input.vue";
import {SEEK_PLAYER} from "@/store/timeLine/timeLineAction";
import {clone} from "@/utils/cloneUtil";


@Component({
  components: {
    WInput,
    CreateAnnotationButton,
    IconsSelector, CenterCenter, WModal, WIcon, WSwitch, AnnotationListElement, WButton
  },
})
export default class CreateWeetInteractionMode extends Vue {

  @Prop()
  private weet!: Weet;

  private loading: boolean = false;

  private showDurationHelper: boolean = false;
  private showStartHelper: boolean = false;

  get timePlayer(): number {
    return store.getters.getTimerPlayer
  }

  get timePlayerHuman(): string {
    return this.getHumanTime(this.timePlayer);
  }

  get isAnnotationInteractionSaveInProgress(){
    return store.getters.isAnnotationInteractionSaveInProgress;
  }

  get selectedAnnotation(): Annotation | undefined {
    return store.getters.getAnnotationSelected;
  }

  get listOfAnnotation():Annotation[]{
    return this.weet.annotations.filter(a=>a.type===AnnotationType.INTERACTION).sort((a,b)=>{
      if(a.time<b.time){
        return -1;
      }else{
        return 1
      }
    })
  }

  get isDurationButtonAivalable(): boolean {
    var annot = this.selectedAnnotation;
    if (!annot) {
      return false;
    }
    var endPosition = this.timePlayer;
    return annot.time + 1000 < endPosition;
  }

  private back() {
    this.$emit('back');
  }

  private getHumanTime(val: number,digit:number=0): string {
    if (!val || val < 0) {
      return '0:00';
    } else {
      return prettyMilliseconds(val, {colonNotation: true, secondsDecimalDigits: digit});
    }
  }

  private stringToSecond(value: string): number {
    value = value + ''.trim();
    if (value.split(":").length === 2) {
      // @ts-ignore (ignore because isNaN accepter string... )
      if (isNaN(value.split(":")[0]) || isNaN(value.split(":")[1])) {
        throw new Error("Not a number");
      }
      var minute = parseFloat(value.split(":")[0]);
      var second = parseFloat(value.split(":")[1]);

      return minute * 60 + second;
    }
    // @ts-ignore
    if(isNaN(value)){
      throw new Error("Not a number");
    }
    return parseFloat(value);
  }

  private async createInteraction() {
    const annotation = new Annotation();
    annotation.type = AnnotationType.INTERACTION;
    annotation.textContent = "";
    if(this.timePlayer<0){
      annotation.time = 0;
    }else {
      annotation.time = this.timePlayer;
    }
    if(annotation.time<500){
      annotation.time=500;
    }
    annotation.duration = 7000;
    annotation.positionType = AnnotationPosition.FREE;
    annotation.theme = AnnotationTheme.DEFAULT;
    annotation.iconMD = '';
    annotation.profil = false;
    annotation.interactionUrl = "";
    annotation.user = null;
    annotation.sound = false;
    annotation.positionX=20;
    annotation.positionY=20;
    annotation.annotationCard.alwaysShow=true;
    var annotationCreated=await store.dispatch(CREATE_ANNOTATION, {weetID: this.weet.weetID, annotation: annotation});
    store.dispatch(SELECT_ANNOTATION,annotationCreated);
    store.dispatch(SEEK_PLAYER, annotation.time)
  }

  private setStart(value: string|number) {
    this.showStartHelper = false;
    try {
      var startPosition = this.stringToSecond(value+"")* 1000;
      if(startPosition===store.getters.getAnnotationSelected.time){
        return;
      }
      if (startPosition >= 0) {
        var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
        annotationUpdated.time = startPosition;
        if(annotationUpdated.time<500){
          annotationUpdated.time=500;
        }
        this.updateAnnotation(annotationUpdated);
        store.dispatch(SEEK_PLAYER, annotationUpdated.time)
      }
    } catch (e) {
      informError(this.$t("createWeetComponent.advancedEditing.annotationMode.option.durationError").toString());
    }
  }

  private setEnd(value: string|number) {
    this.showDurationHelper = false;
    try {
      var endPosition = this.stringToSecond(value+"")*1000;
      var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
      var newDuration=endPosition - annotationUpdated.time;
      if(newDuration===annotationUpdated.duration){
        return;
      }
      annotationUpdated.duration = newDuration;
      if (annotationUpdated.duration < 1000) {
        annotationUpdated.duration = 1000;
      }
      this.updateAnnotation(annotationUpdated);
    } catch (e) {
      informError(this.$t("createWeetComponent.advancedEditing.annotationMode.option.durationError").toString());
    }
  }
  private setDuration(value: string|number){
    this.showDurationHelper = false;
    try {
      var annotationUpdated = clone(store.getters.getAnnotationSelected) as Annotation;
      annotationUpdated.duration = this.stringToSecond(value+"")*1000;
      if (annotationUpdated.duration < 1000) {
        annotationUpdated.duration = 1000;
      }
      this.updateAnnotation(annotationUpdated);
    } catch (e) {
      informError(this.$t("createWeetComponent.advancedEditing.annotationMode.option.durationError").toString());
    }
  }



  private async updateAnnotation(annot: Annotation, withPicture = false) {
    this.loading = true;
    // export img before update
    var dataUrlImage = '';
    if (withPicture) {
      const annotationNode = document.getElementById('annotation-' + annot.annotationID);
      if (!annotationNode) {
        return;
      }
      dataUrlImage = await exportNode2PngDataURL(annotationNode);
      annot.exportStaticUrl = dataUrlImage;
    }

    await store.dispatch(UPDATE_ANNOTATION, {
      weetID: this.weet.weetID, annotation: annot
    }).then(() => {
      store.dispatch(SELECT_ANNOTATION, annot)
      inform(this.$t('createWeetComponent.advancedEditing.annotationMode.edit.success').toString());
      this.loading = false;
    })
  }

  private deleteInteraction(interaction:Annotation){
    try {
      confirmationDanger(this.$t('createWeetComponent.advancedEditing.interactionMode.delete.confirmMessage').toString(),
          this.$t('createWeetComponent.advancedEditing.interactionMode.delete.confirmTitle').toString()).then(() => {
        store.dispatch(DELETE_ANNOTATION, {weetID: this.weet.weetID, annotation: interaction});
        store.dispatch(UNSELECT_ANNOTATION);
      })
    }catch(e){
      // nothing to do
    }
  }

  private beforeDestroy() {
    store.dispatch(UNSELECT_ANNOTATION);
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/shadows.scss';

.weetAdvancedEditingInteractionContainer {
  margin: auto;
  height: 100%;

  .colContainer {
    display: flex;
    width: 100%;
    height: 100%;
    .optionLineTitle {
      margin-top: 8px;
      font-weight: 900;
      color: var(--black);
      font-size: 18px;
    }

    .colLeft {
      background: var(--light);
      min-width: 320px;
      max-width: 320px;
      text-align: left;
      height: 100%;
      animation-duration: 0.2s;
      //position: relative;

      .interactionListContainer {
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;

        .buttonAddList {
          text-align: center;
          margin-top: 8px;
        }
      }
    }

    .colMiddle {
      text-align: left;
      min-width: 320px;
      max-width: 320px;
      height: 100%;

      .interactionListOptionContainer {
        animation-duration: 0.2s;
        width: 100%;
        height: 100%;
        max-height: 100%;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 40px;
      }

      .optionLine {
        display: flex;
        align-items: center;
        margin-top: 4px;
        margin-bottom: 8px;
        transition: all 0.5s ease-in-out;
        &.marginHelper{
          margin-top: 24px;
        }
        .labelOption {
          font-size: 14px;
          color: var(--black);
        }

        .labelTimeInput {
          position: relative;

          .timeInput {
            margin-left: 8px;
            margin-right: 8px;
            width: 70px;
            .input {
              text-align: right;
            }
          }

          .timeHelper {
            animation-duration: 0.5s;
            animation-delay: 0.1s;
            position: absolute;
            font-size: 12px;
            margin-top: 32px;
            color: var(--primary);
            width: 100%;
            cursor: pointer;
            text-align: center;
          }
        }


        .iconPosition {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          text-align: center;
          color: var(--dark);

          &.selected {
            color: var(--black);
            background: var(--light1);
          }
        }

        .iconShowIcon {
          cursor: pointer;
          width: 24px;
          height: 24px;
          border-radius: 8px;
          text-align: center;

          &:hover {
            background: var(--light1);
          }

        }
      }
    }

    .colRight {
      .buttonSave {
        position: absolute;
        right: 16px;
        bottom: 16px;
      }
    }
  }

  // EMPTY STATE
  .emptyState {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    flex-direction: column;
    .emptyStateTitle {
      margin-top: 8px;
      font-size: 16px;
      font-weight: 900;
      color: var(--light2);
      text-align: center;
      letter-spacing: 0.3px;
    }

    .emptyStateDescription {
      margin-top: 8px;
      font-size: 16px;
      text-align: center;
      color: var(--light2);
    }

    .emptyStateButtonAdd {
      position: absolute;
      bottom: 32px;
      width: 100%;
      left: 0px;
      text-align: center;
    }
  }
}
</style>
