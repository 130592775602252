export class AiavatarGenerationModel {
    public text:string;
    public voice:AvatarVoiceAvatar;
    public lang:AvatarLanguage | null;
    public avatar:Avatar;
    public style:AvatarStyle;
    public backgroundUrl:string;
    public version=1;
    public finalOutputHeight:number=1080
    constructor(text:string,
                avatar:Avatar,
                voice:AvatarVoiceAvatar,
                lang:AvatarLanguage | null,
                style:AvatarStyle,
                backgroundUrl:string,version:number) {
        this.text=text;
        this.voice=voice;
        this.lang=lang;
        this.style=style;
        this.avatar=avatar;
        this.backgroundUrl=backgroundUrl;
        this.version=version;
    }
}

export class Text2SpeachParams{
    text:string="";
    voice:AvatarVoiceAvatar=AvatarVoiceAvatar.FR_FR_VIVIENNE;
    lang: AvatarLanguage | null = null;
}

export class VoiceOverParams{
    audioToUpload:Blob;
    mediaID:string;
    constructor(
        audioToUpload:Blob,
        mediaID:string,
    ) {
        this.audioToUpload=audioToUpload;
        this.mediaID=mediaID;
    }
}

export enum AvatarVoiceAvatar {
    EN_US_ADAM = "en-US-AdamMultilingualNeural",
    EN_US_AMANDA = "en-US-AmandaMultilingualNeural",
    EN_US_ANDREW = "en-US-AndrewMultilingualNeural",
    EN_US_AVA = "en-US-AvaMultilingualNeural",
    EN_US_BRANDON = "en-US-BrandonMultilingualNeural",
    EN_US_BRIAN = "en-US-BrianMultilingualNeural",
    EN_US_CHRISTOPHER = "en-US-ChristopherMultilingualNeural",
    EN_US_CORA = "en-US-CoraMultilingualNeural",
    EN_US_DAVIS = "en-US-DavisMultilingualNeural",
    EN_US_DEREK = "en-US-DerekMultilingualNeural",
    EN_US_DUSTIN = "en-US-DustinMultilingualNeural",
    EN_US_EMMA = "en-US-EmmaMultilingualNeural",
    EN_US_EVELYN = "en-US-EvelynMultilingualNeural",
    EN_US_LEWIS = "en-US-LewisMultilingualNeural",
    EN_US_LOLA = "en-US-LolaMultilingualNeural",
    EN_US_NANCY = "en-US-NancyMultilingualNeural",
    EN_US_PHOEBE = "en-US-PhoebeMultilingualNeural",
    EN_US_SAMUEL = "en-US-SamuelMultilingualNeural",
    EN_US_SERENA = "en-US-SerenaMultilingualNeural",
    EN_US_STEFFAN = "en-US-SteffanMultilingualNeural",
    EN_US_ALLOY_TURBO = "en-US-AlloyTurboMultilingualNeural",
    EN_US_ECHO_TURBO = "en-US-EchoTurboMultilingualNeural",
    EN_US_FABLE_TURBO = "en-US-FableTurboMultilingualNeural",
    EN_US_NOVA_TURBO = "en-US-NovaTurboMultilingualNeural",
    EN_US_ONYX_TURBO = "en-US-OnyxTurboMultilingualNeural",
    EN_US_SHIMMER_TURBO = "en-US-ShimmerTurboMultilingualNeural",
    EN_GB_ADA = "en-GB-AdaMultilingualNeural",
    EN_GB_OLLIE = "en-GB-OllieMultilingualNeural",
    DE_DE_SERAPHINA = "de-DE-SeraphinaMultilingualNeural",
    DE_DE_FLORIAN = "de-DE-FlorianMultilingualNeural",
    ES_ES_ARABELLA = "es-ES-ArabellaMultilingualNeural",
    ES_ES_ISIDORA = "es-ES-IsidoraMultilingualNeural",
    ES_ES_TRISTAN = "es-ES-TristanMultilingualNeural",
    ES_ES_XIMENA = "es-ES-XimenaMultilingualNeural",
    FR_FR_LUCIEN = "fr-FR-LucienMultilingualNeural",
    FR_FR_VIVIENNE = "fr-FR-VivienneMultilingualNeural",
    FR_FR_REMY = "fr-FR-RemyMultilingualNeural",
    IT_IT_ALESSIO = "it-IT-AlessioMultilingualNeural",
    IT_IT_GIUSEPPE = "it-IT-GiuseppeMultilingualNeural",
    IT_IT_ISABELLA = "it-IT-IsabellaMultilingualNeural",
    IT_IT_MARCELLO = "it-IT-MarcelloMultilingualNeural",
    JA_JP_MASARU = "ja-JP-MasaruMultilingualNeural",
    KO_KR_HYUNSU = "ko-KR-HyunsuMultilingualNeural",
    PT_BR_MACERIO = "pt-BR-MacerioMultilingualNeural",
    PT_BR_THALITA = "pt-BR-ThalitaMultilingualNeural",
    ZH_CN_XIAOXIAO = "zh-CN-XiaoxiaoMultilingualNeural",
    ZH_CN_XIAOCHEN = "zh-CN-XiaochenMultilingualNeural",
    ZH_CN_XIAOYU = "zh-CN-XiaoyuMultilingualNeural",
    ZH_CN_YUNYI = "zh-CN-YunyiMultilingualNeural",
    ZH_CN_YUNFAN = "zh-CN-YunfanMultilingualNeural",
    ZH_CN_YUNXIAO = "zh-CN-YunxiaoMultilingualNeural"
}

export enum Avatar{
    LISA="lisa",
    HARRY="harry",
    JEFF="jeff",
    LORI="lori",
    MAX="max",
    MEG="meg",
    JEN="jen",
    MARIE="marie",
    ABIGAIL="abigail",
    ARTHUR="arthur",
    DARNEL="darnel",
    HANNAH="hannah",
    MARC="marc",
    ISABELLA="isabella",
    MARTHA="martha",
    MICHELLE="michelle",
    MIKE="mike"
}
export enum AvatarStyle{
    GRACEFUL_SITTING="graceful-sitting",
    CASUAL_SITTING="casual-sitting",
    GRACEFUL_STANDING="graceful-standing",
    TECHNICAL_SITTING="technical-sitting",
    TECHNICAL_STANDING="technical-standing",
    BUSINESS="business",
    CASUAL="casual",
    YOUTHFULL="youthful",
    FORMAL="formal",
    GRACEFUL="graceful",
    DYNAMIC="dynamic",
    STATIC="static"
}

export enum AvatarGender{
    MALE="MALE",
    FEMALE="FEMALE",
    NEUTRAL="NEUTRAL",
    UNKNOWN="UNKNOWN",
}

export enum AvatarLanguage {
    AF_ZA = "af-ZA", // Afrikaans
    SQ_AL = "sq-AL", // Albanian
    AM_ET = "am-ET", // Amharic
    AR_EG = "ar-EG", // Arabic
    HY_AM = "hy-AM", // Armenian
    AZ_AZ = "az-AZ", // Azerbaijani
    ID_ID = "id-ID", // Bahasa Indonesian
    BN_BD = "bn-BD", // Bangla
    EU_ES = "eu-ES", // Basque
    BN_IN = "bn-IN", // Bengali
    BS_BA = "bs-BA", // Bosnian
    BG_BG = "bg-BG", // Bulgarian
    MY_MM = "my-MM", // Burmese
    CA_ES = "ca-ES", // Catalan
    ZH_HK = "zh-HK", // Chinese Cantonese
    ZH_CN = "zh-CN", // Chinese Mandarin
    ZH_TW = "zh-TW", // Chinese Taiwanese
    HR_HR = "hr-HR", // Croatian
    CS_CZ = "cs-CZ", // Czech
    DA_DK = "da-DK", // Danish
    NL_NL = "nl-NL", // Dutch
    EN_US = "en-US", // English
    ET_EE = "et-EE", // Estonian
    FIL_PH = "fil-PH", // Filipino
    FI_FI = "fi-FI", // Finnish
    FR_FR = "fr-FR", // French
    GL_ES = "gl-ES", // Galician
    KA_GE = "ka-GE", // Georgian
    DE_DE = "de-DE", // German
    EL_GR = "el-GR", // Greek
    HE_IL = "he-IL", // Hebrew
    HI_IN = "hi-IN", // Hindi
    HU_HU = "hu-HU", // Hungarian
    IS_IS = "is-IS", // Icelandic
    GA_IE = "ga-IE", // Irish
    IT_IT = "it-IT", // Italian
    JA_JP = "ja-JP", // Japanese
    JV_ID = "jv-ID", // Javanese
    KN_IN = "kn-IN", // Kannada
    KK_KZ = "kk-KZ", // Kazakh
    KM_KH = "km-KH", // Khmer
    KO_KR = "ko-KR", // Korean
    LO_LA = "lo-LA", // Lao
    LV_LV = "lv-LV", // Latvian
    LT_LT = "lt-LT", // Lithuanian
    MK_MK = "mk-MK", // Macedonian
    MS_MY = "ms-MY", // Malay
    ML_IN = "ml-IN", // Malayalam
    MT_MT = "mt-MT", // Maltese
    MN_MN = "mn-MN", // Mongolian
    NE_NP = "ne-NP", // Nepali
    NB_NO = "nb-NO", // Norwegian Bokmål
    PS_AF = "ps-AF", // Pashto
    FA_IR = "fa-IR", // Persian
    PL_PL = "pl-PL", // Polish
    PT_BR = "pt-BR", // Portuguese
    RO_RO = "ro-RO", // Romanian
    RU_RU = "ru-RU", // Russian
    SR_RS = "sr-RS", // Serbian
    SI_LK = "si-LK", // Sinhala
    SK_SK = "sk-SK", // Slovak
    SL_SI = "sl-SI", // Slovene
    SO_SO = "so-SO", // Somali
    ES_ES = "es-ES", // Spanish
    SU_ID = "su-ID", // Sundanese
    SW_KE = "sw-KE", // Swahili
    SV_SE = "sv-SE", // Swedish
    TA_IN = "ta-IN", // Tamil
    TE_IN = "te-IN", // Telugu
    TH_TH = "th-TH", // Thai
    TR_TR = "tr-TR", // Turkish
    UK_UA = "uk-UA", // Ukrainian
    UR_PK = "ur-PK", // Urdu
    UZ_UZ = "uz-UZ", // Uzbek
    VI_VN = "vi-VN", // Vietnamese
    CY_GB = "cy-GB", // Welsh
    ZU_ZA = "zu-ZA" // Zulu
}


export enum AvatarBackground{
    OFFICE_1="OFFICE_1",
    OFFICE_2="OFFICE_2",
    OFFICE_3="OFFICE_3",
    OFFICE_4="OFFICE_4",
    OFFICE_5="OFFICE_5",
    OFFICE_6="OFFICE_6",
    OFFICE_7="OFFICE_7",
    OFFICE_8="OFFICE_8",
    OFFICE_9="OFFICE_9",
    OFFICE_10="OFFICE_10",
    OFFICE_11="OFFICE_11",
    OFFICE_12="OFFICE_12",
    OFFICE_13="OFFICE_13",
    ROOM_1="ROOM_1",
    ROOM_2="ROOM_2",
    ROOM_3="ROOM_3",
    ROOM_4="ROOM_4",
    WALL_1="WALL_1",
    WAREHOUSE_1="WAREHOUSE_1",
    INDUSTRIAL_1="INDUSTRIAL_1",
    INDUSTRIAL_2="INDUSTRIAL_2",
    INDUSTRIAL_3="INDUSTRIAL_3",
    WHITE="WHITE",
    RED="RED",
    YELLOW="YELLOW",
    GREEN="GREEN",
    BLUE="BLUE",
    ORANGE="ORANGE",
    PURPLE="PURPLE",
    BLACK="BLACK"
}
export const LIST_OF_AVATAR=[
    // v2
    {avatar:Avatar.HANNAH,label:"Hannah",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/HANNAH.png'),version:2,freemium:true},
    {avatar:Avatar.MIKE,label:"Mike",style:AvatarStyle.STATIC,gender:AvatarGender.MALE,img:require('@/assets/AIAvatar/MIKE.png'),version:2,freemium:true},
    {avatar:Avatar.MARIE,label:"Marie",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/MARIE.png'),version:2,freemium:false},
    {avatar:Avatar.ABIGAIL,label:"Abigail",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/ABIGAIL.png'),version:2,freemium:false},
    {avatar:Avatar.JEN,label:"Jen",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/JEN.png'),version:2,freemium:false},
    {avatar:Avatar.DARNEL,label:"Darnel",style:AvatarStyle.STATIC,gender:AvatarGender.MALE,img:require('@/assets/AIAvatar/DARNEL.png'),version:2,freemium:false},
    {avatar:Avatar.MICHELLE,label:"Michelle",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/MICHELLE.png'),version:2,freemium:false},
    {avatar:Avatar.ARTHUR,label:"Arthur",style:AvatarStyle.STATIC,gender:AvatarGender.MALE,img:require('@/assets/AIAvatar/ARTHUR.png'),version:2,freemium:false},
    {avatar:Avatar.ISABELLA,label:"Isabella",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/ISABELLA.png'),version:2,freemium:false},
    {avatar:Avatar.MARC,label:"Marc",style:AvatarStyle.STATIC,gender:AvatarGender.MALE,img:require('@/assets/AIAvatar/MARC.png'),version:2,freemium:false},
    {avatar:Avatar.MARTHA,label:"Martha",style:AvatarStyle.STATIC,gender:AvatarGender.FEMALE,img:require('@/assets/AIAvatar/MARTHA.png'),version:2,freemium:false},



]

export const LIST_OF_BACKGROUND=[
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/wall1.jpeg",
        label:"Wall 1 ",
        background:AvatarBackground.WALL_1,freemium:true},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/room1.jpeg",
        label:"Room 1",
        background:AvatarBackground.ROOM_1,freemium:true},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/room2.jpeg",
        label:"Room 2",
        background:AvatarBackground.ROOM_2,freemium:true},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/room3.jpeg",
        label:"Room 3",
        background:AvatarBackground.ROOM_3,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/room4.jpeg",
        label:"Room 4",
        background:AvatarBackground.ROOM_4,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/industrial1.jpeg",
        label:"Industrial 1 ",
        background:AvatarBackground.INDUSTRIAL_1,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/industrial2.jpeg",
        label:"Industrial 2 ",
        background:AvatarBackground.INDUSTRIAL_2,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/industrial3.jpeg",
        label:"Industrial 3 ",
        background:AvatarBackground.INDUSTRIAL_3,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/warehouse1.jpeg",
        label:"Warehouse 1",
        background:AvatarBackground.WAREHOUSE_1,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office1.jpeg",
        label:"Office 1",
    background:AvatarBackground.OFFICE_1,freemium:true},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office2.jpeg",
        label:"Office 2",
        background:AvatarBackground.OFFICE_2,freemium:true},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office3.jpeg",
        label:"Office 3",
        background:AvatarBackground.OFFICE_3,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office4.jpeg",
        label:"Office 4",
        background:AvatarBackground.OFFICE_4,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office5.jpeg",
        label:"Office 5",
        background:AvatarBackground.OFFICE_5,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office6.jpeg",
        label:"Office 6",
        background:AvatarBackground.OFFICE_6,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office7.jpeg",
        label:"Office 7",
        background:AvatarBackground.OFFICE_7,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office8.jpeg",
        label:"Office 8",
        background:AvatarBackground.OFFICE_8,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office9.jpeg",
        label:"Office 9",
        background:AvatarBackground.OFFICE_9,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office10.jpeg",
        label:"Office 10",
        background:AvatarBackground.OFFICE_10,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office11.jpeg",
        label:"Office 11",
        background:AvatarBackground.OFFICE_11,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office12.jpeg",
        label:"Office 12",
        background:AvatarBackground.OFFICE_12,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/office13.jpeg",
        label:"Office 13",
        background:AvatarBackground.OFFICE_13,freemium:false},

    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/white.jpg",
        label:"White",
        background:AvatarBackground.WHITE,freemium:true},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/oragne.jpg",
        label:"Orange",
        background:AvatarBackground.ORANGE,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/yellow.jpg",
        label:"Yellow",
        background:AvatarBackground.YELLOW,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/black.jpg",
        label:"Black",
        background:AvatarBackground.BLACK,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/red.jpg",
        label:"Red",
        background:AvatarBackground.RED,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/blue.jpg",
        label:"Blue",
        background:AvatarBackground.BLUE,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/green.jpg",
        label:"Green",
        background:AvatarBackground.GREEN,freemium:false},
    {url:"https://storage.googleapis.com/weet_prod_data/avatar_background/purple.jpg",
        label:"Purple",
        background:AvatarBackground.PURPLE,freemium:false},

]


export const getLabelForAvatar=(avatar:Avatar,style:AvatarStyle)=>{
    const avatarFound= LIST_OF_AVATAR.find((a)=>{
        return a.avatar===avatar && a.style===style
    })
    return avatarFound?.label;
}
