<template>
</template>

<script lang="ts">
import {Component, Vue, Watch} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import store from '@/store';
import {RecordingState} from '@/enum/RecordingStateEnum';
import {TimeEvent, TimeEventInfo, TimeEventMedia} from '@/store/timeLine/timeEvent';
import {TimeEventType} from '@/enum/TimeEventType';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import {ADD_TIME_EVENT, DELETE_TIME_EVENT, TIMER_RECORD} from '@/store/timeLine/timeLineAction';
import {CREATE_KEY_FRAME, UPDATE_MEDIA_CONTROL_CHANGE} from '@/store/recordingState/recordStateAction';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import {SegmentOfSound} from '@/utils/audioEffect/soundSegmenter';
import {Weet} from '@/store/weet/weetModel';
import {createUnsavedEditingWeet} from '@/utils/weetUtil';
import {getFreeTimeAfterEvent, getTimeEventAfterTime, getTimeEventbeforeTime} from '@/utils/timeLineUtils';
import {MediaMetadata} from "@/store/upload/uploadAction";
import delay from "delay";
import {initTimeEvent} from "@/utils/createWeetUtil";

@Component
export default class TimeLineRecorder extends Vue {


  get isRecording(): boolean {
    return store.getters.getRecordingState === RecordingState.RECORDING;
  }

  get filter(): FilterVideoType {
    return store.getters.getRecorderFilter;
  }

  get recordTime(): number {
    return store.getters.getTimerRecord;
  }

  get fullscreen(): boolean {
    return store.getters.isStickerFullscreen;
  }

  get position(): StickerPosition {
    return store.getters.getStickerPosition;
  }

  get mainVideoRecordingTime(): number {
    return store.getters.getMainVideoRecordingTime;
  }

  get stickersVideoRecordingTime(): number {
    return store.getters.getStickerCurrentRecordingTime;
  }

  get mainMediaId(): string {
    return store.getters.getMainMediaId;
  }

  get isUpload(): boolean {
    return store.getters.getMainRecordSource === SourceVideoType.UPLOAD;
  }

  get getUploadFile(): File {
    return store.getters.getUploadFile
  }

  get isAvatarGenerateInProgress():boolean{
    return store.getters.isAvatarGenerating
  }

  get stickerMediaId(): string {
    return store.getters.getStickersMediaId;
  }

  get keyFrameNeed(): boolean {
    return store.getters.isKeyFrameNeed;
  }

  get soundDetected(): SegmentOfSound {
    return store.getters.getSegmentSound;
  }

  get mediaControlEvent(): TimeEventType {
    return store.getters.getMediaControlEvent;
  }

  get isMainVideoRunning(): boolean {
    return store.getters.isMainVideoRunning;
  }

  get isSecondVideoRunning(): boolean {
    return store.getters.isStickerVideoRunning;
  }

  get timeMaxMillisecond(): number {
    return store.getters.getWeetMaxTime * 1000;
  }

  get myWeet(): Weet | undefined {
    return createUnsavedEditingWeet(store.getters.getEditingWeetID);
  }

  get lastTimeLine(): TimeEvent[] {
    return store.getters.getTimeEvent;
  }

  get getMetadataProcessingFile(): MediaMetadata {
    return store.getters.getMetadataProcessingFile;
  }


  @Watch('filter')
  public onFilterChange() {
    if (this.isRecording) {
      // so we detect an fillter change during the recording so we create an event
      this.createKeyTimeEventElement(TimeEventType.FILTER);
    }
  }

  @Watch('position')
  public onPositionChange() {
    if (this.isRecording) {
      // so we detect an fillter change during the recording so we create an event
      this.createKeyTimeEventElement(TimeEventType.POSITION);
    }
  }

  @Watch('fullscreen')
  public onFullscreenChange() {
    if (this.isRecording) {
      // so we detect an fillter change during the recording so we create an event
      this.createKeyTimeEventElement(TimeEventType.FULLSREEN);
    }
  }

  @Watch('keyFrameNeed')
  public onKeyFrameAsk() {
    if (this.keyFrameNeed) {
      // so a keyframe is ask, we create a time event
      this.createKeyTimeEventElement(TimeEventType.KEYFRAME);
      // we validate the keyframeNeed
      store.dispatch(CREATE_KEY_FRAME, false);
    }
  }


  @Watch('soundDetected')
  public onSoundDetected() {
    const sound = this.soundDetected;

    // console.log(sound);
    // we wait the media is end
    this.$nextTick(() => {
          // is there is no sound detect at the begining
          const weet = this.myWeet;
          if (weet) {
            // careful, if you work with realtime the no sound Detected is event in the past,
            // so the filter or other can be wrong
            // console.log('create Sound');

            // we get the media Initilisation event
            const mediaPlayEvent = getTimeEventbeforeTime(this.lastTimeLine, this.recordTime, TimeEventType.MEDIA_PLAY);
            if (mediaPlayEvent) {
              const OFFSET_TIME = 200;
              // 1 - check if the noSoundEvent exist just after the mediaPlayEvent
              const noSoundEventAfterMedia = getTimeEventAfterTime(this.lastTimeLine, mediaPlayEvent.time, TimeEventType.NO_SOUND_DETECT);
              if (!noSoundEventAfterMedia) {
                const newTime = getFreeTimeAfterEvent(this.lastTimeLine, mediaPlayEvent);
                const initEvent = initTimeEvent(TimeEventType.NO_SOUND_DETECT);
                initEvent.time = newTime; // we put the event just after the media
                initEvent.secondMedia.currentTime = 1;
                initEvent.mainMedia.currentTime = 1;
                store.dispatch(ADD_TIME_EVENT, initEvent);
              }

              // time zero, we have to put the event just after the media Play
              let offsetStartMediaTime = sound.timeStart - OFFSET_TIME;
              if (offsetStartMediaTime <= 0) {
                offsetStartMediaTime = 10;
              }
              const timeStart = mediaPlayEvent.time + offsetStartMediaTime;
              // 2-  now we check if we need to merge the event
              const teNoSoundBeforeOffset = getTimeEventAfterTime(this.lastTimeLine, timeStart, TimeEventType.NO_SOUND_DETECT);
              if (teNoSoundBeforeOffset) {
                // 2.1 Merge so we found an event No sound, we have to merge with the older sound segemnt
                store.dispatch(DELETE_TIME_EVENT, teNoSoundBeforeOffset);
              } else {

                // 2.2 => no merge
                const soundEvent = initTimeEvent(TimeEventType.SOUND_DETECT);
                // we set the real time to be accurate
                soundEvent.time = timeStart;
                soundEvent.secondMedia.currentTime = offsetStartMediaTime;
                soundEvent.mainMedia.currentTime = offsetStartMediaTime;
                store.dispatch(ADD_TIME_EVENT, soundEvent);
              }


              const noSoundEvent = initTimeEvent(TimeEventType.NO_SOUND_DETECT);
              // time zero, we have to put the event just after the media Play
              let offsetEndMediaTime = sound.timeEnd + OFFSET_TIME + 100;
              let timeStartUnDetect = mediaPlayEvent.time + offsetEndMediaTime;
              // check if we are not at the end of the weet
              const endOfWeetEvent = getTimeEventAfterTime(this.lastTimeLine, sound.timeStart, TimeEventType.MEDIA_PAUSE);


              // we check if we are in event launch at the stop signal
              if (endOfWeetEvent && store.getters.isStartRecordingAivailable && timeStartUnDetect > endOfWeetEvent.time) {
                offsetEndMediaTime = getFreeTimeAfterEvent(this.lastTimeLine, endOfWeetEvent.time - 50);
                timeStartUnDetect = offsetEndMediaTime;
              }

              // we set the real time to be accurate
              noSoundEvent.time = timeStartUnDetect;
              noSoundEvent.secondMedia.currentTime = offsetEndMediaTime;
              noSoundEvent.mainMedia.currentTime = offsetEndMediaTime;
              store.dispatch(ADD_TIME_EVENT, noSoundEvent);
            }
          }
        }
    );
  }

  @Watch('mediaControlEvent')
  public onMediaControlChange() {
    if (this.mediaControlEvent !== TimeEventType.NONE) {
      if (this.isRecording) {
        this.createKeyTimeEventElement(this.mediaControlEvent);
      }
      // the media is created, so we reset to none to catch the next Seek
      store.dispatch(UPDATE_MEDIA_CONTROL_CHANGE, TimeEventType.NONE);
    }
  }

  @Watch('isRecording')
  public onRecordingChange() {
    if (this.isRecording) {

      // we wait the ID is generated after the trigger of record
      this.$nextTick(() => {
        this.createKeyTimeEventElement(TimeEventType.MEDIA_PLAY);
      });
    } else {
      this.$nextTick(() => {
        this.createKeyTimeEventElement(TimeEventType.MEDIA_PAUSE);
      });
    }
  }

  @Watch("getUploadFile")
  public onUploadFile() {
    if (this.getUploadFile) {
      // we wait the ID is generated after the trigger of record
      this.$nextTick(() => {
        this.createKeyTimeEventElement(TimeEventType.MEDIA_PLAY);
      });
    }
  }
  @Watch("isAvatarGenerateInProgress")
  public onAvatarGenerate(){
    if (this.isAvatarGenerateInProgress) {
      // we wait the ID is generated after the trigger of record
      this.$nextTick(() => {
        this.createKeyTimeEventElement(TimeEventType.MEDIA_PLAY);
      });
    }
  }

  @Watch("getMetadataProcessingFile")
  public async onProcessingFile() {
    const metadata = this.getMetadataProcessingFile;
   {
      // we update the TimerRecord because a new file is processing
      var currentTimeRecord = store.getters.getTimerRecord;

      currentTimeRecord += (metadata.duration * 1000);
      if (currentTimeRecord > this.timeMaxMillisecond) {
        currentTimeRecord = this.timeMaxMillisecond;
      }
      await store.dispatch(TIMER_RECORD, currentTimeRecord)
      this.createKeyTimeEventElement(TimeEventType.MEDIA_PAUSE);
    }
  }




  public async createKeyTimeEventElement(type: TimeEventType) {
    await store.dispatch(ADD_TIME_EVENT, initTimeEvent(type));
  }
}
</script>

<style scoped lang="scss">
</style>
