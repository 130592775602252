<template>
  <div class="aiAvatarSelectionSource">
      <transition-group name="zoom">
        <AIAvatarSelectionButton v-show="mustShow" :key="'avatarSelectionButton'" />
        <AIAvatarBackgroundSelectionButton v-show="mustShow" :key="'avatarBackgroundSelectionButton'" />
      </transition-group>
    </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import {FilterVideoType} from '@/enum/FilterVideoEnum';
import {SourceVideoType} from '@/enum/SourceVideoEnum';
import store from '@/store';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import StickerFilterButton from '@/components/recorder/sticker/buttons/StickerFilterButton.vue';
import StickerVideoMuteButton from '@/components/recorder/sticker/buttons/StickerVideoMuteButton.vue';
import StickerMicMuteButton from '@/components/recorder/sticker/buttons/StickerMicMuteButton.vue';
import StickerSettingButton from '@/components/recorder/sticker/buttons/StickerSettingButton.vue';
import {getUserAvatar} from '@/utils/util';
import StickerRemoveStickerButton from '@/components/recorder/sticker/buttons/StickerRemoveStickerButton.vue';
import {StickerPosition} from '@/enum/StickerPositionEnum';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import {RECORD_STICKER_POSITION, RECORD_STICKER_VIDEO_MUTE} from '@/store/recordingState/recordStateAction';
import {WebCamIntegrationTypeEnum} from '@/enum/WebCamIntegrationTypeEnum';
import AIAvatarSelectionButton from "@/components/recorder/aiAvatar/buttons/AIAvatarSelectionButton.vue";
import AIAvatarBackgroundSelectionButton
  from "@/components/recorder/aiAvatar/buttons/AIAvatarBackgroundSelectionButton.vue";


@Component({
  components: {
    AIAvatarBackgroundSelectionButton,
    AIAvatarSelectionButton,
  },
  computed: {},
})
export default class StickerLayoutSource extends Vue {

  private mustShow=false;

  public mounted() {
      this.mustShow = true;
  }
}
</script>
<style lang="scss" scoped>
.aiAvatarSelectionSource{
  width: 32px;
  min-height: 32px;
}

</style>
