<template>
  <div class="avatarSelectionPanel">
    <div class="avatarSelectionTitle">
      {{ $t('aiAvatar.selectTitle') }}
    </div>
    <perfect-scrollbar ref="thumbnailScroll">
      <div class="thumbnailGrid">
          <div class="avatarSelection" @click="(isFreemium && !avatar.freemium)?null:onAvatarSelected(avatar.avatar)" v-for="(avatar,index) in listOfAvatar"
               :key="'avatar_'+avatar.avatar+index">
            <span class="avatarThumbnail" :style="'background-image:url('+backgroundSelected?.url+')'"
                  :class="{selected:avatar.avatar===avatarSelected}">
              <div class="avatarSelectedCheck" v-if="avatar.avatar===avatarSelected">
                <w-icon icon="check-circle" color="primary" />
              </div>
              <div class="avatarProfile" :style="'background-image:url('+avatar.img+')'">

              </div>
              <div class="freemiumOverlay" v-if="isFreemium && !avatar.freemium" >
                <upgrade-plan-tag-button size="small"/>
              </div>
            </span>
            <span class="avatarSelectionLabel">{{ avatar.label }}</span>

          </div>
      </div>
    </perfect-scrollbar>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component} from 'vue-property-decorator';
import store from '@/store';

import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import WToolTip from '@/components/wrapper/w-toolTip.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import WSwitch from '@/components/wrapper/w-switch.vue';
import WHelp from '@/components/wrapper/w-help.vue';
import CustomVBUploader from '@/components/recorder/sticker/buttons/CustomVBUploader.vue';
import {Avatar, LIST_OF_AVATAR, LIST_OF_BACKGROUND} from "@/store/AIAvatar/aiavatarGenerationModel";
import {SELECT_AI_AVATAR} from "@/store/AIAvatar/AIAVatarAction";
import UpgradePlanTag from "@/components/upgradePlan/UpgradePlanTag.vue";
import UpgradePlanTagButton from "@/components/upgradePlan/UpgradePlanTagButton.vue";
import {isFreemium} from "@/utils/workspaceUtil";


@Component({
  components: {
    UpgradePlanTagButton,
    UpgradePlanTag,
    CustomVBUploader, WHelp, WSwitch, CenterCenterPanel, WModal, WToolTip, WIcon, WButton, VideoSource},
  computed: {},
})
export default class AIAvatarSelectionPanel extends Vue {


  get avatarSelected() {
    return store.getters.getAvatarSelected
  }

  get backgroundSelected(){
    return LIST_OF_BACKGROUND.find((b)=>{
      return store.getters.getAvatarBabckgroundSelected===b.background
    })
  }

  get listOfAvatar() {
    return LIST_OF_AVATAR.filter((avatar) => {
      if (avatar.version > 1) {
        return true;
      } else {
        return false;
      }
    })
  }

  get isFreemium(): boolean {
    return isFreemium()
  }
  private onAvatarSelected(avatar: Avatar) {
    store.dispatch(SELECT_AI_AVATAR, avatar)
  }


}
</script>

<style lang="scss" scoped>

.avatarSelectionPanel {
  background: var(--white);
  height: 530px;
  padding-bottom: 48px;

  .avatarSelectionTitle {
    font-size: 20px;
    color: var(--black);
    font-weight: 900;
    padding-left: 16px;
    margin-top: 8px;
    margin-bottom: 8px;
  }
  .ps {
    padding-left: 16px;
    padding-right: 16px;
    max-height: 100%;
  }
  .thumbnailGrid {
    display: grid;
    grid-template-columns: 1fr;
    grid-column-gap: 16px;
    .avatarSelection{
      cursor: pointer;
    }

    .avatarThumbnail {
      cursor: pointer;
      position: relative;
      display: block;
      aspect-ratio: 16/9;
      background: var(--light);
      border-radius: 8px;
      width: 100%;
      background-size: cover;
      border: solid 2px var(--light);
      image-rendering: crisp-edges;

      &:hover{
        border-color: var(--light1);
      }
      &.selected {
        border-color: var(--primary);
      }

      .freemiumOverlay{
        opacity: 1;
        background: rgba(10, 8, 47, 0.4);
        width: 100%;
        height: 100%;
        top:0;
        left: 0;
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
      }
      .avatarProfile{
        width: 100%;
        height: 100%;
        background-size: contain;
      }
    }
    .avatarSelectionLabel{
      display: block;
      font-size: 14px;
      font-weight: 700;
      margin-top: 4px;
      margin-bottom: 8px;
    }

    .avatarSelectedCheck {
      height: 24px;
      width: 24px;
      border-radius: 100%;
      top:0px;
      right: 0px;
      position: absolute;
    }

  }
}

</style>
