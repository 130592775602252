import store from "@/store";
import {clone} from "@/utils/cloneUtil";
import {Slide} from "@/store/slides2Video/slides2VideoModels";
import {UPDATE_SLIDES} from "@/store/slides2Video/slides2VideoAction";


export const updateCurrentSlide = (newSlide:Slide) => {
    const currentSlideIndex=store.getters.getCurrentSlideIndex;
    const slidesClone=clone(store.getters.slides) as Slide[];
    for(const slide of slidesClone){
        if(currentSlideIndex+1===slide.order){
            slide.text=newSlide.text;
            slide.script=newSlide.script;
            slide.layout=newSlide.layout;
            slide.imageB64=newSlide.imageB64;
            slide.avatarBackground=newSlide.avatarBackground;
            slide.avatar=newSlide.avatar;
            slide.lang=newSlide.lang;
            slide.voice=newSlide.voice;
            break;
        }
    }
    store.dispatch(UPDATE_SLIDES,slidesClone)
}
