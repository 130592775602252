<template>
  <div class="AIAvatarBackgroundSelectionContainer">
    <!-- this is the "normal" filter panel -->
    <b-dropdown ref="dropDownFilter" position="is-bottom-left"
                aria-role="menu" class="dropdownAIAvatarBackgroundSelection"
                expanded
                animation="fadeLeft" :max-height="450" @active-change="onOpenChange"
                :can-close="true" >
      <w-button class="buttonAIAvatarSelection"
                icon="image-edit-outline"
                size="small" :tooltip="$t('aiAvatar.tooltip.avatarBackgroundSelection')"
                color="text" slot="trigger"/>

      <b-dropdown-item class="noHover" :focusable="false"
                       custom
                       paddingless>
        <AIAvatarBackgroundSelectionPanel />
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script lang="ts">
import Vue from 'vue';
import {Component, Prop} from 'vue-property-decorator';
import store from '@/store';
import VideoSource from '@/components/recorder/videoLayout/source/VideoSource.vue';
import WButton from '@/components/wrapper/w-button.vue';
import WIcon from '@/components/wrapper/w-icon.vue';
import FilterPanel from '@/components/recorder/sticker/buttons/FilterPanel.vue';
import WModal from '@/components/wrapper/w-modal.vue';
import CenterCenterPanel from '@/components/layout/CenterCenter.vue';
import {isGrid1X, isGrid2X} from '@/utils/gridBreakPoint';
import AIAvatarBackgroundSelectionPanel
  from "@/components/recorder/aiAvatar/panel/AIAvatarBackgroundSelectionPanel.vue";


@Component({
  components: {AIAvatarBackgroundSelectionPanel, WButton},
  computed: {},
})
export default class AIAvatarBackgroundSelectionButton extends Vue {

  public onOpenChange(value) {
    this.$emit('open', value);
  }

}
</script>

<style lang="scss">
@import '@/scss/shadows.scss';
@import '@/scss/gridBreakPoint.scss';

.dropdownAIAvatarBackgroundSelection {
  .dropdown-menu {
    margin-top: -402px;
    margin-right: 48px;
    max-height: 500px;
    min-width: 256px;
    border-radius: 16px;
    //  @extend .shadow1;
    @include GRID2X {
      margin-top: -0px;
      margin-right: 0px;
    }
    @include GRID1X {
      margin-top: -0px;
      margin-right: 0px;
    }
  }
}

</style>

<style lang="scss" scoped>
@import '@/scss/shadows.scss';

.backgroundFilterPanel {
  background: white;
  margin: auto;
  width: 400px;
  max-width: 400px;
  border-radius: 16px;
  position: relative;
}

</style>
