import {Avatar, AvatarBackground, AvatarLanguage, AvatarVoiceAvatar} from "@/store/AIAvatar/aiavatarGenerationModel";

export class Slide{
    public imageB64:string="";
    public order:number=0;
    public text="";
    public script="";
    public layout: ScriptSlideLayout=ScriptSlideLayout.AVATAR_BOTTOM_LEFT;
    public avatarBackground: AvatarBackground|null=null
    public avatar:Avatar|null=null;
    public lang:AvatarLanguage|null=null;
    public voice:AvatarVoiceAvatar|null=null;
}

export enum ScriptSlideLayout{
    AVATAR_ONLY="AVATAR_ONLY",
    AVATAR_BOTTOM_LEFT="AVATAR_BOTTOM_LEFT",
    VOICE_ONLY="VOICE_ONLY"
}
